import { Actions } from '../types/authTypes';
import { setJWT, setToken } from '../../persistentState';
import { BackendConfigResponse } from '@assisto/typescript-axios/lib';

export type AuthStateType = {
	config: BackendConfigResponse | null;
	authLoading: boolean;
};

const initialState: AuthStateType = {
	config: null,
	authLoading: false,
};

export default function reducer(
	state: AuthStateType = initialState,
	actions: {
		jwt?: string;
		token?: string;
		config?: BackendConfigResponse;
		type: string;
	},
): any {
	switch (actions.type) {
		case Actions.AUTH_AUTOLOGIN_REQUEST:
			return {
				...state,
				authLoading: true,
			};

		case Actions.AUTH_SIGN_IN_SUCCESS:
			if (actions.jwt) {
				setJWT(actions.jwt);
			}

			if (actions.token) {
				setToken(actions.token);
			}

			return {
				...state,
			};

		case Actions.AUTH_SIGN_OUT:
			return {
				...state,
			};

		case Actions.AUTH_GET_CONFIG_SUCCESS:
			return {
				...state,
				config: actions.config,
				authLoading: false,
			};

		default:
			return state;
	}
}
