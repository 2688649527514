import { Actions } from '../../types/composite/compositeTypes';
import { Composite } from '@assisto/typescript-axios/lib';

export type CompositeBaseStateType = {
	composite: Composite | null;
	composites: Composite[] | null;
	loadingGet: boolean;
	loadingGetAll: boolean;
	loadingSave: boolean;
	loadingDelete: boolean;
	loadingGetPreset: boolean;
	page: number;
	limit: number;
};

const initialState: CompositeBaseStateType = {
	composite: null,
	composites: null,
	loadingGet: false,
	loadingGetAll: false,
	loadingSave: false,
	loadingDelete: false,
	loadingGetPreset: false,
	page: 1,
	limit: 0,
};

export default function reducer(
	state: CompositeBaseStateType = initialState,
	actions: {
		composites?: Composite[];
		page?: number;
		limit?: number;
		composite?: Composite;
		type: string;
		compositeId?: number;
	},
): any {
	switch (actions.type) {
		case Actions.COMPOSITE_GET_ALL_REQUEST:
			return {
				...state,
				composites: null,
				loadingGetAll: true,
			};
		case Actions.COMPOSITE_GET_ALL_SUCCESS:
			return {
				...state,
				composites: actions.composites,
				loadingGetAll: false,
				page: actions.page,
				limit: actions.limit,
			};

		case Actions.COMPOSITE_GET_REQUEST:
			return {
				...state,
				composite: null,
				loadingGet: true,
			};
		case Actions.COMPOSITE_GET_SUCCESS:
			return {
				...state,
				composite: actions.composite,
				loadingGet: false,
			};
		case Actions.COMPOSITE_GET_FAILURE:
			return {
				...state,
				loadingGet: false,
			};
		case Actions.COMPOSITE_GET_ALL_FAILURE:
			return {
				...state,
				loadingGetAll: false,
			};
		case Actions.COMPOSITE_SAVE_REQUEST:
			return {
				...state,
				loadingSave: true,
			};
		case Actions.COMPOSITE_SAVE_FAILURE:
			return {
				...state,
				loadingSave: false,
			};
		case Actions.COMPOSITE_SAVE_SUCCESS:
			return {
				...state,
				composite: actions.composite,
				loadingSave: false,
			};
		case Actions.COMPOSITE_DELETE_REQUEST:
			return {
				...state,
				composite: null,
				loadingDelete: true,
			};
		case Actions.COMPOSITE_DELETE_SUCCESS:
			return {
				...state,
				composites: [...(state.composites || [])].filter((el) => el.id !== actions.compositeId),
				loadingDelete: false,
			};

		default:
			return state;
	}
}
