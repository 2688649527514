import * as React from 'react';
import styled from 'styled-components/macro';

import {
	Grid,
	Hidden,
	List,
	ListItemText as MuiListItemText,
	ListItem as MuiListItem,
	ListItemProps as MuiListItemProps,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface ListItemProps extends MuiListItemProps {
	component?: string;
	href?: string;
	button: boolean | undefined;
	target?: string;
}

const Wrapper = styled.footer`
	padding: ${(props) => props.theme.spacing(1) / 4}px ${(props) => props.theme.spacing(4)}px;
	background: ${(props) => props.theme.footer.background};
	position: relative;
`;

const ListItem = styled(MuiListItem)<ListItemProps>`
	width: auto;
	padding-left: ${(props) => props.theme.spacing(2)}px;
	padding-right: ${(props) => props.theme.spacing(2)}px;
	&,
	&:hover,
	&:active {
		color: #ff0000;
	}
`;

const LiWrapper = styled.li`
	display: inline-block;
`;

const ListItemText = styled(MuiListItemText)`
	span {
		color: ${(props) => props.theme.footer.color};
	}
`;

function Footer() {
	const { t } = useTranslation();
	return (
		<Wrapper>
			<Grid container spacing={0}>
				<Hidden smDown>
					<Grid container item xs={12} md={6}>
						<List>
							{/*<ListItem button={true} component="a" href="#">
                <ListItemText primary="Support" />
              </ListItem>
              <ListItem button={true} component="a" href="#">
                <ListItemText primary="Help Center" />
              </ListItem>*/}
							<LiWrapper>
								<ListItem button={true} component="a" href="/general/imprint" target="_blank">
									<ListItemText primary={t('IMPRINT')} />
								</ListItem>
							</LiWrapper>
							<LiWrapper>
								<ListItem button={true} component="a" href="/general/privacy" target="_blank">
									<ListItemText primary={t('PRIVACY')} />
								</ListItem>
							</LiWrapper>
						</List>
					</Grid>
				</Hidden>
				<Grid container item xs={12} md={6} justify="flex-end">
					<List>
						<MuiListItem>
							<ListItemText primary={`© ${new Date().getFullYear()} - ${t('COPYRIGHT')}`} />
						</MuiListItem>
					</List>
				</Grid>
			</Grid>
		</Wrapper>
	);
}

export default Footer;
