export enum Actions {
	MESSENGER_APP_GET_REQUEST = 'MESSENGER_APP_GET_REQUEST',
	MESSENGER_APP_GET_SUCCESS = 'MESSENGER_APP_GET_SUCCESS',
	MESSENGER_APP_GET_FAILURE = 'MESSENGER_APP_GET_FAILURE',
	MESSENGER_APP_GET_ALL_REQUEST = 'MESSENGER_APP_GET_ALL_REQUEST',
	MESSENGER_APP_GET_ALL_SUCCESS = 'MESSENGER_APP_GET_ALL_SUCCESS',
	MESSENGER_APP_GET_ALL_FAILURE = 'MESSENGER_APP_GET_ALL_FAILURE',
	MESSENGER_APP_SAVE_REQUEST = 'MESSENGER_APP_SAVE_REQUEST',
	MESSENGER_APP_SAVE_SUCCESS = 'MESSENGER_APP_SAVE_SUCCESS',
	MESSENGER_APP_SAVE_FAILURE = 'MESSENGER_APP_SAVE_FAILURE',
}

export enum KnowledgeActions {
	KNOWLEDGE_GET_SUCCESS = 'KNOWLEDGE_GET_SUCCESS',
	KNOWLEDGE_GET_ALL_REQUEST = 'KNOWLEDGE_GET_ALL_REQUEST',
	KNOWLEDGE_GET_ALL_SUCCESS = 'KNOWLEDGE_GET_ALL_SUCCESS',
	KNOWLEDGE_GET_ALL_FAILURE = 'KNOWLEDGE_GET_ALL_FAILURE',
	KNOWLEDGE_SAVE_REQUEST = 'KNOWLEDGE_SAVE_REQUEST',
	KNOWLEDGE_EDIT_SUCCESS = 'KNOWLEDGE_EDIT_SUCCESS',
	KNOWLEDGE_SAVE_SUCCESS = 'KNOWLEDGE_SAVE_SUCCESS',
	KNOWLEDGE_SAVE_FAILURE = 'KNOWLEDGE_SAVE_FAILURE',
	KNOWLEDGE_SAVE_ALL_REQUEST = 'KNOWLEDGE_SAVE_ALL_REQUEST',
	KNOWLEDGE_SAVE_ALL_FAILURE = 'KNOWLEDGE_SAVE_ALL_FAILURE',
	KNOWLEDGE_SAVE_ALL_SUCCESS = 'KNOWLEDGE_SAVE_ALL_SUCCESS',
	KNOWLEDGE_DELETE_REQUEST = 'KNOWLEDGE_DELETE_REQUEST',
	KNOWLEDGE_DELETE_FAILURE = 'KNOWLEDGE_DELETE_FAILURE',
	KNOWLEDGE_DELETE_SUCCESS = 'KNOWLEDGE_DELETE_SUCCESS',
}

export enum FaqActions {
	FAQ_GET_ALL_REQUEST = 'FAQ_GET_ALL_REQUEST',
	FAQ_GET_ALL_SUCCESS = 'FAQ_GET_ALL_SUCCESS',
	FAQ_GET_ALL_FAILURE = 'FAQ_GET_ALL_FAILURE',
	FAQ_GET_SUCCESS = 'FAQ_GET_SUCCESS',
	FAQ_SAVE_SUCCESS = 'FAQ_SAVE_SUCCESS',
	FAQ_EDIT_SUCCESS = 'FAQ_EDIT_SUCCESS',
	FAQ_SAVE_REQUEST = 'FAQ_SAVE_REQUEST',
	FAQ_SAVE_FAILURE = 'FAQ_SAVE_FAILURE',
	FAQ_SAVE_ALL_REQUEST = 'FAQ_SAVE_ALL_REQUEST',
	FAQ_SAVE_ALL_FAILURE = 'FAQ_SAVE_ALL_FAILURE',
	FAQ_SAVE_ALL_SUCCESS = 'FAQ_SAVE_ALL_SUCCESS',
	FAQ_DELETE_REQUEST = 'FAQ_DELETE_REQUEST',
	FAQ_DELETE_FAILURE = 'FAQ_DELETE_FAILURE',
	FAQ_DELETE_SUCCESS = 'FAQ_DELETE_SUCCESS',
}

export enum OnboardingActions {
	ONBOARDING_GET_ALL_REQUEST = 'ONBOARDING_GET_ALL_REQUEST',
	ONBOARDING_GET_ALL_SUCCESS = 'ONBOARDING_GET_ALL_SUCCESS',
	ONBOARDING_GET_ALL_FAILURE = 'ONBOARDING_GET_ALL_FAILURE',
	ONBOARDING_SAVE_REQUEST = 'ONBOARDING_SAVE_REQUEST',
	ONBOARDING_SAVE_SUCCESS = 'ONBOARDING_SAVE_SUCCESS',
	ONBOARDING_EDIT_SUCCESS = 'ONBOARDING_EDIT_SUCCESS',
	ONBOARDING_SAVE_FAILURE = 'ONBOARDING_SAVE_FAILURE',
	ONBOARDING_SAVE_ALL_REQUEST = 'ONBOARDING_SAVE_ALL_REQUEST',
	ONBOARDING_SAVE_ALL_FAILURE = 'ONBOARDING_SAVE_ALL_FAILURE',
	ONBOARDING_SAVE_ALL_SUCCESS = 'ONBOARDING_SAVE_ALL_SUCCESS',
	ONBOARDING_DELETE_REQUEST = 'ONBOARDING_DELETE_REQUEST',
	ONBOARDING_DELETE_SUCCESS = 'ONBOARDING_DELETE_SUCCESS',
	ONBOARDING_DELETE_FAILURE = 'ONBOARDING_DELETE_FAILURE',
}

export enum AccessibilityActions {
	ACCESSIBILITY_INFORMATION_GET_ALL_REQUEST = 'ACCESSIBILITY_INFORMATION_GET_ALL_REQUEST',
	ACCESSIBILITY_INFORMATION_GET_ALL_SUCCESS = 'ACCESSIBILITY_INFORMATION_GET_ALL_SUCCESS',
	ACCESSIBILITY_INFORMATION_GET_ALL_FAILURE = 'ACCESSIBILITY_INFORMATION_GET_ALL_FAILURE',
	ACCESSIBILITY_INFORMATION_SAVE_REQUEST = 'ACCESSIBILITY_INFORMATION_SAVE_REQUEST',
	ACCESSIBILITY_INFORMATION_SAVE_SUCCESS = 'ACCESSIBILITY_INFORMATION_SAVE_SUCCESS',
	ACCESSIBILITY_INFORMATION_SAVE_FAILURE = 'ACCESSIBILITY_INFORMATION_SAVE_FAILURE',
}
export enum PrivacyPolicyActions {
	PRIVACY_POLICY_GET_ALL_REQUEST = 'PRIVACY_POLICY_GET_ALL_REQUEST',
	PRIVACY_POLICY_GET_ALL_SUCCESS = 'PRIVACY_POLICY_GET_ALL_SUCCESS',
	PRIVACY_POLICY_GET_ALL_FAILURE = 'PRIVACY_POLICY_GET_ALL_FAILURE',
	PRIVACY_POLICY_SAVE_REQUEST = 'PRIVACY_POLICY_SAVE_REQUEST',
	PRIVACY_POLICY_SAVE_SUCCESS = 'PRIVACY_POLICY_SAVE_SUCCESS',
	PRIVACY_POLICY_SAVE_FAILURE = 'PRIVACY_POLICY_SAVE_FAILURE',
}
export enum TermsOfServiceActions {
	TERMS_OF_SERVICE_GET_ALL_REQUEST = 'TERMS_OF_SERVICE_GET_ALL_REQUEST',
	TERMS_OF_SERVICE_GET_ALL_SUCCESS = 'TERMS_OF_SERVICE_GET_ALL_SUCCESS',
	TERMS_OF_SERVICE_GET_ALL_FAILURE = 'TERMS_OF_SERVICE_GET_ALL_FAILURE',
	TERMS_OF_SERVICE_SAVE_REQUEST = 'TERMS_OF_SERVICE_SAVE_REQUEST',
	TERMS_OF_SERVICE_SAVE_SUCCESS = 'TERMS_OF_SERVICE_SAVE_SUCCESS',
	TERMS_OF_SERVICE_SAVE_FAILURE = 'TERMS_OF_SERVICE_SAVE_FAILURE',
}
