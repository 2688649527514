import { combineReducers } from 'redux';
import address, { AddressStateType } from './addressReducer';
import contact, { ContactStateType } from './contactReducer';
import banking, { BankingStateType } from './bankingReducer';
import config, { CompanyConfigStateType } from './configReducer';
import company, { CompanyBaseStateType } from './companyReducer';

export const companyReducer = combineReducers({
	address,
	contact,
	banking,
	config,
	company,
});

export interface CompanyStateType {
	address: AddressStateType;
	contact: ContactStateType;
	banking: BankingStateType;
	config: CompanyConfigStateType;
	company: CompanyBaseStateType;
}
