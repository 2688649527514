import {
	CompositesApi,
	CompanyProductsApi,
	UsersApi,
	Configuration,
	CompanyApi,
	ProductsApi,
	ProductWebBasicApi,
	ProductMessengerAppApi,
	AgreementsApi,
	BeranetUsersApi,
	MasksApi,
	OnboardingPagesApi,
	ProductMessengerAppFaqApi,
	ProductMessengerAppKnowledgeApi,
	ProductMessengerAppAccessibilityInformationApi,
	TermsOfServiceApi,
	PrivacyPolicyApi,
	ProductGroupsApi,
	ProductGroupsKnowledgeApi,
	ProductGroupsFaqApi,
	ProductGroupsAccessibilityInformationApi,
	ProductWebMessengerApi,
	ProductWebMessengerKnowledgeApi,
	ProductWebMessengerFaqApi,
	ProductWebMessengerAccessibilityInformationApi,
	ProductWebBasicFaqApi,
	ProductQuestoApi,
	GlobalNotificationsApi,
} from '@assisto/typescript-axios/lib';

let GlobalNotifications = new GlobalNotificationsApi();

export function configureGlobalNotifications(configuration: Configuration) {
	GlobalNotifications = new GlobalNotificationsApi(configuration);
}
let Composites = new CompositesApi();

export function configureComposites(configuration: Configuration) {
	Composites = new CompositesApi(configuration);
}

let Users = new UsersApi(new Configuration(), process.env.REACT_APP_BACKEND_BASE_URL);

export function configureUsers(configuration: Configuration) {
	Users = new UsersApi(configuration, process.env.REACT_APP_BACKEND_BASE_URL);
}

let CompanyProduct = new CompanyProductsApi();

export function configureCompanyProduct(configuration: Configuration) {
	CompanyProduct = new CompanyProductsApi(configuration);
}

let Company = new CompanyApi();

export function configureCompany(configuration: Configuration) {
	Company = new CompanyApi(configuration);
}

let Products = new ProductsApi();

export function configureProducts(configuration: Configuration) {
	Products = new ProductsApi(configuration);
}

let WebBasic = new ProductWebBasicApi();

export function configureWebBasic(configuration: Configuration) {
	WebBasic = new ProductWebBasicApi(configuration);
}

let Questo = new ProductQuestoApi();

export function configureQuesto(configuration: Configuration) {
	Questo = new ProductQuestoApi(configuration);
}

let WebBasicFaqs = new ProductWebBasicFaqApi();

export function configureWebBasicFaqs(configuration: Configuration) {
	WebBasicFaqs = new ProductWebBasicFaqApi(configuration);
}

let MessengerApp = new ProductMessengerAppApi();

export function configureMessengerApp(configuration: Configuration) {
	MessengerApp = new ProductMessengerAppApi(configuration);
}
// Groups

let Groups = new ProductGroupsApi();

export function configureGroups(configuration: Configuration) {
	Groups = new ProductGroupsApi(configuration);
}

let GroupsFaq = new ProductGroupsFaqApi();

export function configureGroupsFaq(configuration: Configuration) {
	GroupsFaq = new ProductGroupsFaqApi(configuration);
}

let GroupsKnowledge = new ProductGroupsKnowledgeApi();
export function configureGroupsKnowledge(configuration: Configuration) {
	GroupsKnowledge = new ProductGroupsKnowledgeApi(configuration);
}

let GroupsAccessibilityInformation = new ProductGroupsAccessibilityInformationApi();
export function configureGroupsAccessibility(configuration: Configuration) {
	GroupsAccessibilityInformation = new ProductGroupsAccessibilityInformationApi(configuration);
}

let GroupsOnboarding = new OnboardingPagesApi();

export function configureGroupsOnboarding(configuration: Configuration) {
	GroupsOnboarding = new OnboardingPagesApi(configuration);
}

// Web Messenger

let WebMessenger = new ProductWebMessengerApi();

export function configureWebMessenger(configuration: Configuration) {
	WebMessenger = new ProductWebMessengerApi(configuration);
}

let WebMessengerFaq = new ProductWebMessengerFaqApi();

export function configureWebMessengerFaq(configuration: Configuration) {
	WebMessengerFaq = new ProductWebMessengerFaqApi(configuration);
}

let WebMessengerKnowledge = new ProductWebMessengerKnowledgeApi();
export function configureWebMessengerKnowledge(configuration: Configuration) {
	WebMessengerKnowledge = new ProductWebMessengerKnowledgeApi(configuration);
}

let WebMessengerAccessibilityInformation = new ProductWebMessengerAccessibilityInformationApi();
export function configureWebMessengerAccessibility(configuration: Configuration) {
	WebMessengerAccessibilityInformation = new ProductWebMessengerAccessibilityInformationApi(
		configuration,
	);
}

//Messenger
let WebMessengerOnboarding = new OnboardingPagesApi();

export function configureWebMessengerOnboarding(configuration: Configuration) {
	WebMessengerOnboarding = new OnboardingPagesApi(configuration);
}

let MessengerOnboarding = new OnboardingPagesApi();

export function configureMessengerAppOnboarding(configuration: Configuration) {
	MessengerOnboarding = new OnboardingPagesApi(configuration);
}
let MessengerFaq = new ProductMessengerAppFaqApi();

export function configureMessengerAppFaq(configuration: Configuration) {
	MessengerFaq = new ProductMessengerAppFaqApi(configuration);
}

let MessengerKnowledge = new ProductMessengerAppKnowledgeApi();
export function configureMessengerAppKnowledge(configuration: Configuration) {
	MessengerKnowledge = new ProductMessengerAppKnowledgeApi(configuration);
}

let MessengerAccessibilityInformation = new ProductMessengerAppAccessibilityInformationApi();
export function configureMessengerAppAccessibility(configuration: Configuration) {
	MessengerAccessibilityInformation = new ProductMessengerAppAccessibilityInformationApi(
		configuration,
	);
}

let PrivacyPolicyInformation = new PrivacyPolicyApi();
export function configurePrivacyPolicy(configuration: Configuration) {
	PrivacyPolicyInformation = new PrivacyPolicyApi(configuration);
}

let TermsOfService = new TermsOfServiceApi();
export function configureTermsOfService(configuration: Configuration) {
	TermsOfService = new TermsOfServiceApi(configuration);
}

let Agreement = new AgreementsApi();

export function configureAgreements(configuration: Configuration) {
	Agreement = new AgreementsApi(configuration);
}

let BeranetUsers = new BeranetUsersApi();

export function beranetUsers(configuration: Configuration) {
	BeranetUsers = new BeranetUsersApi(configuration);
}

let Masks = new MasksApi();

export function configureMasks(configuration: Configuration) {
	Masks = new MasksApi(configuration);
}

export function configureAll(
	configuration: Configuration = new Configuration(),
	basePath: string | undefined = process.env.REACT_APP_BACKEND_BASE_URL,
) {
	Users = new UsersApi(configuration, basePath);
	Company = new CompanyApi(configuration, basePath);
	CompanyProduct = new CompanyProductsApi(configuration, basePath);
	Products = new ProductsApi(configuration, basePath);
	WebBasic = new ProductWebBasicApi(configuration, basePath);
	WebBasicFaqs = new ProductWebBasicFaqApi(configuration, basePath);
	MessengerApp = new ProductMessengerAppApi(configuration, basePath);
	MessengerOnboarding = new OnboardingPagesApi(configuration, basePath);
	MessengerFaq = new ProductMessengerAppFaqApi(configuration, basePath);
	MessengerKnowledge = new ProductMessengerAppKnowledgeApi(configuration, basePath);
	MessengerAccessibilityInformation = new ProductMessengerAppAccessibilityInformationApi(
		configuration,
		basePath,
	);
	//Groups
	Groups = new ProductGroupsApi(configuration, basePath);
	GroupsFaq = new ProductGroupsFaqApi(configuration, basePath);
	GroupsKnowledge = new ProductGroupsKnowledgeApi(configuration, basePath);
	GroupsAccessibilityInformation = new ProductGroupsAccessibilityInformationApi(
		configuration,
		basePath,
	);
	GroupsOnboarding = new OnboardingPagesApi(configuration, basePath);
	//Web Messenger
	WebMessenger = new ProductWebMessengerApi(configuration, basePath);
	WebMessengerFaq = new ProductWebMessengerFaqApi(configuration, basePath);
	WebMessengerKnowledge = new ProductWebMessengerKnowledgeApi(configuration, basePath);
	WebMessengerAccessibilityInformation = new ProductWebMessengerAccessibilityInformationApi(
		configuration,
		basePath,
	);
	WebMessengerOnboarding = new OnboardingPagesApi(configuration, basePath);

	PrivacyPolicyInformation = new PrivacyPolicyApi(configuration, basePath);
	TermsOfService = new TermsOfServiceApi(configuration, basePath);
	Agreement = new AgreementsApi(configuration, basePath);
	BeranetUsers = new BeranetUsersApi(configuration, basePath);
	Masks = new MasksApi(configuration, basePath);
	Composites = new CompositesApi(configuration, basePath);
	GlobalNotifications = new GlobalNotificationsApi(configuration, basePath);
	Questo = new ProductQuestoApi(configuration, basePath);
}

configureAll(new Configuration());

export {
	Users,
	CompanyProduct,
	Company,
	Products,
	MessengerApp,
	MessengerOnboarding,
	MessengerFaq,
	MessengerKnowledge,
	MessengerAccessibilityInformation,
	Groups,
	GroupsOnboarding,
	GroupsFaq,
	GroupsKnowledge,
	GroupsAccessibilityInformation,
	WebMessenger,
	WebMessengerOnboarding,
	WebMessengerFaq,
	WebMessengerKnowledge,
	WebMessengerAccessibilityInformation,
	PrivacyPolicyInformation,
	TermsOfService,
	WebBasic,
	WebBasicFaqs,
	Agreement,
	BeranetUsers,
	Masks,
	Composites,
	Questo,
	GlobalNotifications,
};

export enum CompanyContactTypeIntegerEnum {
	INVOICE_DISPATCH = 2,
	PROJECT = 1,
}
