import { Actions } from '../../types/company/addressTypes';
import { CompanyAddress } from '@assisto/typescript-axios/lib';

export type AddressStateType = {
	addresses: CompanyAddress[] | null;
	loadingGetAll: boolean;
	loadingSave: boolean;
};

const initialState: AddressStateType = {
	addresses: null,
	loadingGetAll: false,
	loadingSave: false,
};

export default function reducer(
	state: AddressStateType = initialState,
	actions: {
		addresses?: CompanyAddress[];
		type: string;
	},
): any {
	switch (actions.type) {
		case Actions.ADDRESS_GET_ALL_REQUEST:
			return {
				...state,
				addresses: null,
				loadingGetAll: true,
			};
		case Actions.ADDRESS_GET_ALL_SUCCESS:
			return {
				...state,
				addresses: actions.addresses,
				loadingGetAll: false,
			};

		case Actions.ADDRESS_SAVE_REQUEST:
			return {
				...state,
				loadingSave: true,
			};
		case Actions.ADDRESS_SAVE_SUCCESS:
			return {
				...state,
				loadingSave: false,
			};

		default:
			return state;
	}
}
