export enum Actions {
	GLOBAL_NOTIFICATIONS_GET_REQUEST = 'GLOBAL_NOTIFICATIONS_GET_REQUEST',
	GLOBAL_NOTIFICATIONS_GET_SUCCESS = 'GLOBAL_NOTIFICATIONS_GET_SUCCESS',
	GLOBAL_NOTIFICATIONS_GET_FAILURE = 'GLOBAL_NOTIFICATIONS_GET_FAILURE',
	GLOBAL_NOTIFICATIONS_GET_ALL_REQUEST = 'GLOBAL_NOTIFICATIONS_GET_ALL_REQUEST',
	GLOBAL_NOTIFICATIONS_GET_ALL_SUCCESS = 'GLOBAL_NOTIFICATIONS_GET_ALL_SUCCESS',
	GLOBAL_NOTIFICATIONS_GET_ALL_FAILURE = 'GLOBAL_NOTIFICATIONS_GET_ALL_FAILURE',
	GLOBAL_NOTIFICATIONS_SAVE_REQUEST = 'GLOBAL_NOTIFICATIONS_SAVE_REQUEST',
	GLOBAL_NOTIFICATIONS_SAVE_SUCCESS = 'GLOBAL_NOTIFICATIONS_SAVE_SUCCESS',
	GLOBAL_NOTIFICATIONS_SAVE_FAILURE = 'GLOBAL_NOTIFICATIONS_SAVE_FAILURE',
	GLOBAL_NOTIFICATIONS_DELETE_REQUEST = 'GLOBAL_NOTIFICATIONS_DELETE_REQUEST',
	GLOBAL_NOTIFICATIONS_DELETE_SUCCESS = 'GLOBAL_NOTIFICATIONS_DELETE_SUCCESS',
	GLOBAL_NOTIFICATIONS_DELETE_FAILURE = 'GLOBAL_NOTIFICATIONS_DELETE_FAILURE',
}
