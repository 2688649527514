import { Actions } from '../types/agreementTypes';
import { Agreement } from '@assisto/typescript-axios/lib';
import { AgreementType } from '../actions/agreementsActions';

export type AgreementStateType = {
	licenseAgreement: Agreement | null;
	dataProcessingAgreement: Agreement | null;
	loadingLicenseAgreement: boolean;
	loadingDataProcessingAgreement: boolean;
};

const initialState: AgreementStateType = {
	licenseAgreement: null,
	dataProcessingAgreement: null,
	loadingLicenseAgreement: false,
	loadingDataProcessingAgreement: false,
};

export default function reducer(
	state: AgreementStateType = initialState,
	actions: {
		agreement?: Agreement;
		agreementType: AgreementType;
		type: string;
	},
): any {
	switch (actions.type) {
		case Actions.AGREEMENT_GET_LATEST_REQUEST:
			if (actions.agreementType === AgreementType.LICENSE_AGREEMENT) {
				return {
					...state,
					licenseAgreement: null,
					loadingLicenseAgreement: true,
				};
			}

			if (actions.agreementType === AgreementType.DATA_PROCESSING) {
				return {
					...state,
					dataProcessingAgreement: null,
					loadingDataProcessingAgreement: true,
				};
			}

			return {
				...state,
			};

		case Actions.AGREEMENT_GET_LATEST_SUCCESS:
			if (actions.agreementType === AgreementType.LICENSE_AGREEMENT) {
				return {
					...state,
					licenseAgreement: actions.agreement,
					loadingLicenseAgreement: true,
				};
			}

			if (actions.agreementType === AgreementType.DATA_PROCESSING) {
				return {
					...state,
					dataProcessingAgreement: actions.agreement,
					loadingDataProcessingAgreement: true,
				};
			}

			return {
				...state,
			};

		default:
			return state;
	}
}
