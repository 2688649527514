import { Actions } from '../../types/products/companyTypes';
import { CompanyProductWithProduct } from '@assisto/typescript-axios/lib';

export type CompanyProductsStateType = {
	products: CompanyProductWithProduct[] | null;
	loading: boolean;
	saveLoading: boolean;
};

const initialState: CompanyProductsStateType = {
	products: null,
	loading: false,
	saveLoading: false,
};

export default function reducer(
	state: CompanyProductsStateType = initialState,
	actions: {
		products?: CompanyProductWithProduct[];
		type: string;
	},
): any {
	switch (actions.type) {
		case Actions.COMPANY_PRODUCTS_GET_ALL_REQUEST:
			return {
				...state,
				products: null,
				loading: true,
			};
		case Actions.COMPANY_PRODUCTS_GET_ALL_SUCCESS:
			return {
				...state,
				products: actions.products,
				loading: false,
			};
		case Actions.COMPANY_PRODUCTS_GET_REQUEST:
			return {
				...state,
				products: null,
				loading: true,
			};
		case Actions.COMPANY_PRODUCTS_GET_SUCCESS:
			return {
				...state,
				products: actions.products,
				loading: false,
			};
		case Actions.COMPANY_PRODUCTS_CREATE_REQUEST:
			return {
				...state,
				products: null,
				loading: true,
			};
		case Actions.COMPANY_PRODUCTS_CREATE_SUCCESS:
			return {
				...state,
				products: actions,
				loading: false,
			};
		case Actions.COMPANY_PRODUCTS_CREATE_FAILURE:
			return {
				...state,
				loading: false,
			};
		case Actions.COMPANY_PRODUCTS_GET_FAILURE:
			return {
				...state,
				loading: false,
			};
		case Actions.COMPANY_PRODUCTS_GET_ALL_FAILURE:
			return {
				...state,
				loading: false,
			};
		case Actions.COMPANY_PRODUCTS_GET_PRESET_REQUEST:
			return {
				...state,
				products: null,
				loading: true,
			};
		case Actions.COMPANY_PRODUCTS_GET_PRESET_SUCCESS:
			return {
				...state,
				products: actions,
				loadingGetPreset: false,
			};
		case Actions.COMPANY_PRODUCTS_GET_PRESET_FAILURE:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
}
