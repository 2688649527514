import { Actions } from '../../types/globalNotificationsTypes';
import { GlobalNotification } from '@assisto/typescript-axios/lib';

export type GlobalNotificationBaseStateType = {
	globalNotification: GlobalNotification | null;
	globalNotifications: GlobalNotification[] | null;
	loadingGet: boolean;
	loadingGetAll: boolean;
	loadingSave: boolean;
	loadingDelete: boolean;
	page: number;
	limit: number;
};

const initialState: GlobalNotificationBaseStateType = {
	globalNotification: null,
	globalNotifications: null,
	loadingGet: false,
	loadingGetAll: false,
	loadingSave: false,
	loadingDelete: false,
	page: 1,
	limit: 0,
};

export default function reducer(
	state: GlobalNotificationBaseStateType = initialState,
	actions: {
		globalNotifications?: GlobalNotification[];
		page?: number;
		limit?: number;
		globalNotification?: GlobalNotification;
		type: string;
		globalNotificationId?: number;
	},
): any {
	switch (actions.type) {
		case Actions.GLOBAL_NOTIFICATIONS_GET_REQUEST:
			return {
				...state,
				globalNotification: null,
				loadingGet: true,
			};
		case Actions.GLOBAL_NOTIFICATIONS_GET_SUCCESS:
			return {
				...state,
				globalNotification: actions.globalNotification,
				loadingGet: false,
			};
		case Actions.GLOBAL_NOTIFICATIONS_GET_FAILURE:
			return {
				...state,
				loadingGet: false,
			};
		case Actions.GLOBAL_NOTIFICATIONS_GET_ALL_REQUEST:
			return {
				...state,
				globalNotifications: null,
				loadingGetAll: true,
			};
		case Actions.GLOBAL_NOTIFICATIONS_GET_ALL_SUCCESS:
			return {
				...state,
				globalNotifications: actions.globalNotifications,
				loadingGetAll: false,
				page: actions.page,
				limit: actions.limit,
			};
		case Actions.GLOBAL_NOTIFICATIONS_GET_ALL_FAILURE:
			return {
				...state,
				loadingGetAll: false,
			};
		case Actions.GLOBAL_NOTIFICATIONS_SAVE_REQUEST:
			return {
				...state,
				loadingSave: true,
			};
		case Actions.GLOBAL_NOTIFICATIONS_SAVE_SUCCESS:
			return {
				...state,
				globalNotifications: actions.globalNotifications,
				loadingSave: false,
				page: actions.page,
				limit: actions.limit,
			};
		case Actions.GLOBAL_NOTIFICATIONS_SAVE_FAILURE:
			return {
				...state,
				loadingSave: false,
			};
		case Actions.GLOBAL_NOTIFICATIONS_DELETE_REQUEST:
			return {
				...state,
				loadingDelete: true,
			};
		case Actions.GLOBAL_NOTIFICATIONS_DELETE_SUCCESS:
			return {
				...state,
				globalNotifications: [...(state.globalNotifications || [])].filter(
					(el) => el.id !== actions.globalNotificationId,
				),
				limit: state.limit - 1,
				loadingDelete: false,
			};

		default:
			return state;
	}
}
