import {
	Actions,
	KnowledgeActions,
	FaqActions,
	OnboardingActions,
	AccessibilityActions,
	PrivacyPolicyActions,
	TermsOfServiceActions,
} from '../../types/products/GroupsTypes';
import {
	ProductGroups,
	ProductGroupsWithOnboarding,
	ProductGroupsKnowledge,
	ProductGroupsFaq,
	OnboardingPage,
	ProductGroupsAccessibilityInformation,
	PrivacyPolicy,
	TermsOfService,
} from '@assisto/typescript-axios/lib';

interface ExtendedProductGroups extends ProductGroupsWithOnboarding {
	knowledge?: ProductGroupsKnowledge[];
	faqs?: ProductGroupsFaq[];
	logo?: string;
	accessibility_information?: ProductGroupsAccessibilityInformation[];
	privacy_policy?: PrivacyPolicy[];
	terms_of_service?: TermsOfService[];
	domain: string;
	description: string;
	name: string;
	about_us: string;
	title: string;
}

export type GroupsStateType = {
	product: ExtendedProductGroups | null;
	loading: boolean;
	saveLoading: boolean;
};

const initialState: GroupsStateType = {
	product: null,
	loading: false,
	saveLoading: false,
};

export default function reducer(
	state: GroupsStateType = initialState,
	actions: {
		product?: ExtendedProductGroups | ProductGroups;
		knowledge?: ProductGroupsKnowledge[];
		article?: ProductGroupsKnowledge;
		onboarding_pages?: OnboardingPage[];
		onboarding_page: OnboardingPage;
		faqs?: ProductGroupsFaq[];
		faq?: ProductGroupsFaq;
		accessibility_information?: ProductGroupsAccessibilityInformation[];
		privacy_policy: PrivacyPolicy[];
		terms_of_service: TermsOfService[];
		id?: number;
		type: string;
	},
): any {
	switch (actions.type) {
		case Actions.GROUPS_GET_REQUEST:
			return {
				...state,
				product: null,
				loading: true,
			};
		case Actions.GROUPS_GET_SUCCESS:
			return {
				...state,
				product: actions.product,
				loading: false,
			};
		case Actions.GROUPS_GET_ALL_REQUEST:
			return {
				...state,
				product: null,
				loading: true,
			};
		case Actions.GROUPS_GET_ALL_SUCCESS:
			return {
				...state,
				product: actions.product,
				loading: false,
			};
		case Actions.GROUPS_SAVE_REQUEST:
			return {
				...state,
				saveLoading: true,
			};
		case Actions.GROUPS_SAVE_SUCCESS:
			return {
				...state,
				product: Object.assign(state.product!, actions.product),
				saveLoading: false,
			};
		case Actions.GROUPS_SAVE_FAILURE:
			return {
				...state,
				saveLoading: false,
			};

		// GROUPS_KNOWLEDGE:

		case KnowledgeActions.GROUPS_KNOWLEDGE_GET_ALL_REQUEST:
			return {
				...state,
				loading: true,
			};
		case KnowledgeActions.GROUPS_KNOWLEDGE_GET_ALL_FAILURE:
			return {
				...state,
				loading: false,
			};
		case KnowledgeActions.GROUPS_KNOWLEDGE_GET_ALL_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					knowledge: actions.knowledge,
				},
				loading: false,
			};

		case KnowledgeActions.GROUPS_KNOWLEDGE_SAVE_ALL_REQUEST:
			return {
				...state,
				saveLoading: true,
			};

		case KnowledgeActions.GROUPS_KNOWLEDGE_SAVE_ALL_FAILURE:
			return {
				...state,
				saveLoading: false,
			};

		case KnowledgeActions.GROUPS_KNOWLEDGE_SAVE_ALL_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					knowledge: actions.knowledge,
				},
				saveLoading: false,
			};
		case KnowledgeActions.GROUPS_KNOWLEDGE_SAVE_REQUEST:
			return {
				...state,
				saveLoading: true,
			};
		case KnowledgeActions.GROUPS_KNOWLEDGE_SAVE_FAILURE:
			return {
				...state,
				saveLoading: false,
			};

		case KnowledgeActions.GROUPS_KNOWLEDGE_EDIT_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					knowledge: state.product!.knowledge!.map((article) =>
						article.id !== actions.article!.id ? article : Object.assign(article, actions.article),
					),
				},
				saveLoading: false,
			};

		case KnowledgeActions.GROUPS_KNOWLEDGE_SAVE_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					knowledge: [...state.product?.knowledge!, actions.article],
				},
				saveLoading: false,
			};
		case KnowledgeActions.GROUPS_KNOWLEDGE_DELETE_REQUEST:
			return {
				...state,
				loading: true,
			};

		case KnowledgeActions.GROUPS_KNOWLEDGE_DELETE_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					knowledge: [...state.product!.knowledge!].filter((article) => article.id !== actions.id),
				},
				loading: false,
			};

		case KnowledgeActions.GROUPS_KNOWLEDGE_DELETE_FAILURE:
			return {
				...state,
				loading: false,
			};

		// GROUPS_FAQ:

		case FaqActions.GROUPS_FAQ_GET_SUCCESS:
			return {
				...state,
				saveLoading: false,
			};

		case FaqActions.GROUPS_FAQ_SAVE_REQUEST:
			return {
				...state,
				saveLoading: true,
			};
		case FaqActions.GROUPS_FAQ_SAVE_FAILURE:
			return {
				...state,
				saveLoading: false,
			};

		case FaqActions.GROUPS_FAQ_EDIT_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					faqs: state.product!.faqs!.map((faq) =>
						faq.id !== actions.faq!.id ? faq : Object.assign(faq, actions.faq),
					),
				},
				saveLoading: false,
			};
		case FaqActions.GROUPS_FAQ_SAVE_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					faqs: [...state.product?.faqs!, actions.faq],
				},
				saveLoading: false,
			};

		case FaqActions.GROUPS_FAQ_SAVE_ALL_REQUEST:
			return {
				...state,
				saveLoading: true,
			};

		case FaqActions.GROUPS_FAQ_SAVE_ALL_FAILURE:
			return {
				...state,
				saveLoading: false,
			};

		case FaqActions.GROUPS_FAQ_SAVE_ALL_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					faqs: actions.faqs,
				},
				saveLoading: false,
			};

		case FaqActions.GROUPS_FAQ_GET_ALL_REQUEST:
			return {
				...state,
				loading: true,
			};

		case FaqActions.GROUPS_FAQ_GET_ALL_FAILURE:
			return {
				...state,
				loading: false,
			};
		case FaqActions.GROUPS_FAQ_GET_ALL_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					faqs: actions.faqs,
				},
				loading: false,
			};
		case FaqActions.GROUPS_FAQ_DELETE_REQUEST:
			return {
				...state,
				loading: true,
			};

		case FaqActions.GROUPS_FAQ_DELETE_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					faqs: [...state.product!.faqs!].filter((faq) => faq.id !== actions.id),
				},
				loading: false,
			};

		case FaqActions.GROUPS_FAQ_DELETE_FAILURE:
			return {
				...state,
				loading: false,
			};

		// GROUPS_ONBOARDING:

		case OnboardingActions.GROUPS_ONBOARDING_GET_ALL_REQUEST:
			return {
				...state,
				loading: true,
			};

		case OnboardingActions.GROUPS_ONBOARDING_GET_ALL_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					onboarding_pages: actions.onboarding_pages,
				},
				loading: false,
			};

		case OnboardingActions.GROUPS_ONBOARDING_GET_ALL_FAILURE:
			return {
				...state,
				loading: false,
			};
		case OnboardingActions.GROUPS_ONBOARDING_SAVE_REQUEST:
			return {
				...state,
				saveLoading: true,
			};

		case OnboardingActions.GROUPS_ONBOARDING_EDIT_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					onboarding_pages: state.product!.onboarding_pages!.map((page) =>
						page.id !== actions.onboarding_page.id
							? page
							: Object.assign(page, actions.onboarding_page),
					),
				},
				saveLoading: false,
			};

		case OnboardingActions.GROUPS_ONBOARDING_SAVE_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					onboarding_pages: [...state.product?.onboarding_pages!, actions.onboarding_page],
				},
				saveLoading: false,
			};

		case OnboardingActions.GROUPS_ONBOARDING_SAVE_FAILURE:
			return {
				...state,
				saveLoading: false,
			};
		case OnboardingActions.GROUPS_ONBOARDING_SAVE_ALL_REQUEST:
			return {
				...state,
				saveLoading: true,
			};

		case OnboardingActions.GROUPS_ONBOARDING_SAVE_ALL_FAILURE:
			return {
				...state,
				saveLoading: false,
			};

		case OnboardingActions.GROUPS_ONBOARDING_SAVE_ALL_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					onboarding_pages: actions.onboarding_pages,
				},
				saveLoading: false,
			};

		case OnboardingActions.GROUPS_ONBOARDING_DELETE_REQUEST:
			return {
				...state,
				loading: true,
			};

		case OnboardingActions.GROUPS_ONBOARDING_DELETE_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					onboarding_pages: [...state.product!.onboarding_pages!].filter(
						(page) => page.id !== actions.id,
					),
				},
				loading: false,
			};

		case OnboardingActions.GROUPS_ONBOARDING_DELETE_FAILURE:
			return {
				...state,
				loading: false,
			};

		//GROUPS_ACCESSIBILITY
		case AccessibilityActions.GROUPS_ACCESSIBILITY_INFORMATION_GET_ALL_REQUEST:
			return {
				...state,
				loading: true,
			};
		case AccessibilityActions.GROUPS_ACCESSIBILITY_INFORMATION_GET_ALL_FAILURE:
			return {
				...state,
				loading: false,
			};
		case AccessibilityActions.GROUPS_ACCESSIBILITY_INFORMATION_GET_ALL_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					accessibility_information: actions.accessibility_information,
				},
				loading: false,
			};

		case AccessibilityActions.GROUPS_ACCESSIBILITY_INFORMATION_SAVE_REQUEST:
			return {
				...state,
				saveLoading: true,
			};
		case AccessibilityActions.GROUPS_ACCESSIBILITY_INFORMATION_SAVE_FAILURE:
			return {
				...state,
				saveLoading: false,
			};
		case AccessibilityActions.GROUPS_ACCESSIBILITY_INFORMATION_SAVE_SUCCESS:
			return {
				...state,
				saveLoading: false,
			};

		case PrivacyPolicyActions.GROUPS_PRIVACY_POLICY_GET_ALL_REQUEST:
			return {
				...state,
				loading: true,
			};
		case PrivacyPolicyActions.GROUPS_PRIVACY_POLICY_GET_ALL_FAILURE:
			return {
				...state,
				loading: false,
			};
		case PrivacyPolicyActions.GROUPS_PRIVACY_POLICY_GET_ALL_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					privacy_policy: actions.privacy_policy,
				},
				loading: false,
			};

		case PrivacyPolicyActions.GROUPS_PRIVACY_POLICY_SAVE_REQUEST:
			return {
				...state,
				saveLoading: true,
			};
		case PrivacyPolicyActions.GROUPS_PRIVACY_POLICY_SAVE_FAILURE:
			return {
				...state,
				saveLoading: false,
			};
		case PrivacyPolicyActions.GROUPS_PRIVACY_POLICY_SAVE_SUCCESS:
			return {
				...state,
				saveLoading: false,
			};

		case TermsOfServiceActions.GROUPS_TERMS_OF_SERVICE_GET_ALL_REQUEST:
			return {
				...state,
				loading: true,
			};
		case TermsOfServiceActions.GROUPS_TERMS_OF_SERVICE_GET_ALL_FAILURE:
			return {
				...state,
				loading: false,
			};
		case TermsOfServiceActions.GROUPS_TERMS_OF_SERVICE_GET_ALL_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					terms_of_service: actions.terms_of_service,
				},
				loading: false,
			};

		case TermsOfServiceActions.GROUPS_TERMS_OF_SERVICE_SAVE_REQUEST:
			return {
				...state,
				saveLoading: true,
			};
		case TermsOfServiceActions.GROUPS_TERMS_OF_SERVICE_SAVE_FAILURE:
			return {
				...state,
				saveLoading: false,
			};
		case TermsOfServiceActions.GROUPS_TERMS_OF_SERVICE_SAVE_SUCCESS:
			return {
				...state,
				saveLoading: false,
			};

		default:
			return state;
	}
}
