export type SignInType = {
	email: string;
	password: string;
};

export type SignUpType = {
	name: string;
	company: string;
	email: string;
	password: string;
};

export type ResetPasswordType = {
	email: string;
};

export enum Actions {
	AUTH_SIGN_IN_REQUEST = 'AUTH_SIGN_IN_REQUEST',
	AUTH_SIGN_IN_SUCCESS = 'AUTH_SIGN_IN_SUCCESS',
	AUTH_SIGN_IN_FAILURE = 'AUTH_SIGN_IN_FAILURE',
	AUTH_SIGN_OUT = 'AUTH_SIGN_OUT',
	AUTH_SIGN_UP_REQUEST = 'AUTH_SIGN_UP_REQUEST',
	AUTH_SIGN_UP_SUCCESS = 'AUTH_SIGN_UP_SUCCESS',
	AUTH_SIGN_UP_FAILURE = 'AUTH_SIGN_UP_FAILURE',
	AUTH_RESET_PASSWORD_REQUEST = 'AUTH_RESET_PASSWORD_REQUEST',
	AUTH_RESET_PASSWORD_SUCCESS = 'AUTH_RESET_PASSWORD_SUCCESS',
	AUTH_RESET_PASSWORD_FAILURE = 'AUTH_RESET_PASSWORD_FAILURE',
	AUTH_AUTOLOGIN_REQUEST = 'AUTH_AUTOLOGIN_REQUEST',
	AUTH_GET_CONFIG_REQUEST = 'AUTH_GET_CONFIG_REQUEST',
	AUTH_GET_CONFIG_SUCCESS = 'AUTH_GET_CONFIG_SUCCESS',
	AUTH_GET_CONFIG_FAILURE = 'AUTH_GET_CONFIG_FAILURE',
}
