export enum Actions {
	COMPOSITE_GET_REQUEST = 'COMPOSITE_GET_REQUEST',
	COMPOSITE_GET_SUCCESS = 'COMPOSITE_GET_SUCCESS',
	COMPOSITE_GET_FAILURE = 'COMPOSITE_GET_FAILURE',
	COMPOSITE_GET_ALL_REQUEST = 'COMPOSITE_GET_ALL_REQUEST',
	COMPOSITE_GET_ALL_SUCCESS = 'COMPOSITE_GET_ALL_SUCCESS',
	COMPOSITE_GET_ALL_FAILURE = 'COMPOSITE_GET_ALL_FAILURE',
	COMPOSITE_SAVE_REQUEST = 'COMPOSITE_SAVE_REQUEST',
	COMPOSITE_SAVE_SUCCESS = 'COMPOSITE_SAVE_SUCCESS',
	COMPOSITE_SAVE_FAILURE = 'COMPOSITE_SAVE_FAILURE',
	COMPOSITE_CREATE_REQUEST = 'COMPOSITE_CREATE_REQUEST',
	COMPOSITE_CREATE_SUCCESS = 'COMPOSITE_CREATE_SUCCESS',
	COMPOSITE_CREATE_FAILURE = 'COMPOSITE_CREATE_FAILURE',
	COMPOSITE_DELETE_REQUEST = 'COMPOSITE_DELETE_REQUEST',
	COMPOSITE_DELETE_SUCCESS = 'COMPOSITE_DELETE_SUCCESS',
	COMPOSITE_DELETE_FAILURE = 'COMPOSITE_DELETE_FAILURE',
	COMPOSITE_GET_PRESET_REQUEST = 'COMPOSITE_GET_PRESET_REQUEST',
	COMPOSITE_GET_PRESET_SUCCESS = 'COMPOSITE_GET_PRESET_SUCCESS',
	COMPOSITE_GET_PRESET_FAILURE = 'COMPOSITE_GET_PRESET_FAILURE',
}
