// Theme
export const THEME_SET = 'THEME_SET';
export const THEMES = {
	DEFAULT: 'DEFAULT',
	DARK: 'DARK',
	LIGHT: 'LIGHT',
	BLUE: 'BLUE',
	GREEN: 'GREEN',
	INDIGO: 'INDIGO',
};
