import { NotificationsActions } from '../actions/notificationsActions';
import {
	DEFAULT_NOTIFICATIONS_STATE,
	Notification,
	NotificationsState,
} from '../types/notificationsTypes';

export const notificationsReducer = (
	state: NotificationsState = DEFAULT_NOTIFICATIONS_STATE,
	action: { type: NotificationsActions; notification: Notification },
): NotificationsState => {
	switch (action.type) {
		case 'ADD_NOTIFICATION':
			const key = state.nextNotification + 1; // increment notification key

			return {
				...state,
				nextNotification: key, // save new notification key in state
				notifications: [{ ...action.notification, key: key }, ...state.notifications], // add notification with incremented key at the start of the list
			};

		case 'REMOVE_NOTIFICATION':
			return {
				...state,
				notifications: state.notifications.filter(
					(notification) => notification.key !== action.notification.key,
				), // remove notification from the list for given key
			};

		default:
			return state;
	}
};
