import { put, takeLatest, all } from 'redux-saga/effects';
import { Actions } from '../types/authTypes';
import { Users } from '../../utils/api';
import { push } from 'connected-react-router';
import { setJWT, setToken } from '../../persistentState';

function* authSuccess(payload: { type: Actions; jwt?: string; token?: string }) {
	if (payload.jwt) {
		setJWT(payload.jwt);
	}

	if (payload.token) {
		setToken(payload.token);
	}

	yield put({ type: Actions.AUTH_GET_CONFIG_REQUEST });
	return;
}

function* getConfig(payload: { type: Actions }) {
	try {
		const resp = yield Users.getConfig();
		yield put({ type: Actions.AUTH_GET_CONFIG_SUCCESS, config: resp.data });
		yield put(push('/dashboard'));
	} catch (ex) {
		yield put({ type: Actions.AUTH_GET_CONFIG_FAILURE });
	}
	return;
}

function* authSuccessWatcher() {
	yield takeLatest(Actions.AUTH_SIGN_IN_SUCCESS, authSuccess);
}

function* getConfigWatcher() {
	yield takeLatest(Actions.AUTH_GET_CONFIG_REQUEST, getConfig);
}

export default function* authSaga() {
	yield all([authSuccessWatcher(), getConfigWatcher()]);
}
