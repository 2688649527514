import { combineReducers } from 'redux';
import composites, { CompositeBaseStateType } from './compositeReducer';

export const compositeReducer = combineReducers({
	composites,
});

export interface CompositeStateType {
	composites: CompositeBaseStateType;
}
