import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import GeneralLayout from '../layouts/General';
import Page404 from '../pages/general/Page404';
import { RouteChildType, RouteType } from '../types/routes';
import { ROUTES } from './index';
import { flatten } from './util';

const renderRoutes = (routes: Array<RouteType>) =>
	routes.map(({ guard, name, children, path, layout: Layout }, idx: number) => {
		const Guard = guard || React.Fragment;
		return (
			<Route
				key={idx}
				path={path}
				render={(props) => (
					<Guard>
						<Layout>
							<Switch>
								{children ? renderChildren(children) : null}
								<Route
									render={() => (
										<GeneralLayout>
											<Page404 />
										</GeneralLayout>
									)}
								/>
							</Switch>
						</Layout>
					</Guard>
				)}
			/>
		);
	});

const renderChildren = (childRoutes: Array<RouteChildType>) => {
	// flatten child arrays
	const flatRoutes = flatten(childRoutes);

	return flatRoutes.map(({ guard, name, children, path, component: Component }, idx: number) => {
		const Guard = guard || React.Fragment;
		return (
			<Route
				key={idx}
				exact
				path={path}
				render={(props) => (
					<Guard>
						<Component {...props} />
					</Guard>
				)}
			/>
		);
	});
};

const Routes = () => (
	<Switch>
		{renderRoutes(ROUTES)}
		<Route path="/">
			<Redirect to="/backend/settings" />
		</Route>
		<Route
			render={() => (
				<GeneralLayout>
					<Page404 />
				</GeneralLayout>
			)}
		/>
	</Switch>
);

export default Routes;
