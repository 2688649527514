import { Actions } from '../../types/company/contactTypes';
import { CompanyContact } from '@assisto/typescript-axios/lib';

export type ContactStateType = {
	contacts: CompanyContact[] | null;
	loadingGetAll: boolean;
	loadingSave: any;
};

const initialState: ContactStateType = {
	contacts: null,
	loadingGetAll: false,
	loadingSave: {},
};

export default function reducer(
	state: ContactStateType = initialState,
	actions: {
		contacts?: CompanyContact[];
		contact?: CompanyContact;
		type: string;
	},
): any {
	switch (actions.type) {
		case Actions.CONTACT_GET_ALL_REQUEST:
			return {
				...state,
				contacts: null,
				loadingGetAll: true,
			};
		case Actions.CONTACT_GET_ALL_SUCCESS:
			return {
				...state,
				contacts: actions.contacts,
				loadingGetAll: false,
			};

		case Actions.CONTACT_SAVE_REQUEST:
			return {
				...state,
				loadingSave: { [actions.contact?.contact_type ?? '']: true },
			};
		case Actions.CONTACT_SAVE_SUCCESS:
			return {
				...state,
				loadingSave: { [actions.contact?.contact_type ?? '']: false },
			};

		default:
			return state;
	}
}
