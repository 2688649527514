import { Actions } from '../../types/users/usersTypes';
import { User } from '@assisto/typescript-axios/lib';

export type UserBaseStateType = {
	user: User | null;
	users: User[] | null;
	loadingGet: boolean;
	loadingGetAll: boolean;
	loadingSave: boolean;
	page: number;
	limit: number;
};

const initialState: UserBaseStateType = {
	user: null,
	users: null,
	loadingGet: false,
	loadingGetAll: false,
	loadingSave: false,
	page: 1,
	limit: 0,
};

export default function reducer(
	state: UserBaseStateType = initialState,
	actions: {
		users?: User[];
		page?: number;
		limit?: number;
		user?: User;
		type: string;
	},
): any {
	switch (actions.type) {
		case Actions.USERS_GET_ALL_REQUEST:
			return {
				...state,
				users: null,
				loadingGetAll: true,
			};
		case Actions.USERS_GET_ALL_SUCCESS:
			return {
				...state,
				users: actions.users,
				loadingGetAll: false,
				page: actions.page,
				limit: actions.limit,
			};

		case Actions.USERS_GET_REQUEST:
			return {
				...state,
				user: null,
				loadingGet: true,
			};
		case Actions.USERS_GET_SUCCESS:
			return {
				...state,
				user: actions.user,
				loadingGet: false,
			};

		case Actions.USERS_SAVE_REQUEST:
			return {
				...state,
				loadingSave: true,
			};
		case Actions.USERS_SAVE_SUCCESS:
			return {
				...state,
				loadingSave: false,
			};

		default:
			return state;
	}
}
