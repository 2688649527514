import { Actions } from '../../types/company/configTypes';
import { CompanyGetConfigResponse } from '@assisto/typescript-axios/lib';

export type CompanyConfigStateType = {
	config: CompanyGetConfigResponse | null;
	loading: boolean;
	loadingGlobalConfig: boolean;
	saveConfig: boolean;
	loadingConfig: boolean;
};

const initialState: CompanyConfigStateType = {
	config: null,
	loadingGlobalConfig: false,
	loadingConfig: false,
	saveConfig: false,
	loading: false,
};

export default function reducer(
	state: CompanyConfigStateType = initialState,
	actions: {
		config?: CompanyGetConfigResponse;
		type: string;
	},
): any {
	switch (actions.type) {
		case Actions.COMPANY_CONFIG_GET_REQUEST:
			return {
				...state,
				contacts: null,
				loading: true,
			};
		case Actions.COMPANY_CONFIG_GET_SUCCESS:
			return {
				...state,
				config: actions.config,
				loading: false,
			};
		case Actions.COMPANY_CONFIG_GET_FAILURE:
			return {
				...state,
				loading: false,
			};
		case Actions.COMPANY_ASSISTO_CONFIG_GET_REQUEST:
			return {
				...state,
				config: null,
				loadingConfig: true,
			};
		case Actions.COMPANY_ASSISTO_CONFIG_GET_SUCCESS:
			return {
				...state,
				config: actions.config,
				loadingConfig: false,
			};
		case Actions.COMPANY_ASSISTO_CONFIG_GET_FAILURE:
			return {
				...state,
				loadingConfig: false,
			};
		case Actions.COMPANY_GLOBAL_CONFIG_SAVE_REQUEST:
			return {
				...state,
				config: null,
				loadingGlobalConfig: true,
			};
		case Actions.COMPANY_GLOBAL_CONFIG_SAVE_SUCCESS:
			return {
				...state,
				config: actions.config,
				loadingGlobalConfig: false,
			};
		case Actions.COMPANY_GLOBAL_CONFIG_SAVE_FAILURE:
			return {
				...state,
				loadingGlobalConfig: false,
			};
		case Actions.COMPANY_CONFIG_SAVE_REQUEST:
			return {
				...state,
				config: null,
				saveConfig: true,
			};
		case Actions.COMPANY_CONFIG_SAVE_SUCCESS:
			return {
				...state,
				config: actions.config,
				saveConfig: false,
			};
		case Actions.COMPANY_CONFIG_SAVE_FAILURE:
			return {
				...state,
				saveConfig: false,
			};
		default:
			return state;
	}
}
