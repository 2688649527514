import {
	Actions,
	KnowledgeActions,
	FaqActions,
	OnboardingActions,
	AccessibilityActions,
	PrivacyPolicyActions,
	TermsOfServiceActions,
} from '../../types/products/webMessengerTypes';
import {
	ProductWebMessenger,
	ProductWebMessengerWithOnboarding,
	ProductWebMessengerKnowledge,
	ProductWebMessengerFaq,
	OnboardingPage,
	ProductWebMessengerAccessibilityInformation,
	PrivacyPolicy,
	TermsOfService,
} from '@assisto/typescript-axios/lib';

interface ExtendedProductWebMessenger extends ProductWebMessengerWithOnboarding {
	knowledge?: ProductWebMessengerKnowledge[];
	faqs?: ProductWebMessengerFaq[];
	logo?: string;
	accessibility_information?: ProductWebMessengerAccessibilityInformation[];
	privacy_policy?: string;
	terms_of_service?: TermsOfService[];
}

export type WebMessengerStateType = {
	product: ExtendedProductWebMessenger | null;
	loading: boolean;
	saveLoading: boolean;
};

const initialState: WebMessengerStateType = {
	product: null,
	loading: false,
	saveLoading: false,
};

export default function reducer(
	state: WebMessengerStateType = initialState,
	actions: {
		product?: ExtendedProductWebMessenger | ProductWebMessenger;
		knowledge?: ProductWebMessengerKnowledge[];
		article?: ProductWebMessengerKnowledge;
		onboarding_pages?: OnboardingPage[];
		onboarding_page: OnboardingPage;
		faqs?: ProductWebMessengerFaq[];
		faq?: ProductWebMessengerFaq;
		accessibility_information?: ProductWebMessengerAccessibilityInformation[];
		privacy_policy: PrivacyPolicy[];
		terms_of_service: TermsOfService[];
		id?: number;
		type: string;
	},
): any {
	switch (actions.type) {
		case Actions.WEB_MESSENGER_GET_REQUEST:
			return {
				...state,
				product: null,
				loading: true,
			};
		case Actions.WEB_MESSENGER_GET_SUCCESS:
			return {
				...state,
				product: actions.product,
				loading: false,
			};
		case Actions.WEB_MESSENGER_GET_ALL_REQUEST:
			return {
				...state,
				product: null,
				loading: true,
			};
		case Actions.WEB_MESSENGER_GET_ALL_SUCCESS:
			return {
				...state,
				product: actions.product,
				loading: false,
			};
		case Actions.WEB_MESSENGER_SAVE_REQUEST:
			return {
				...state,
				saveLoading: true,
			};
		case Actions.WEB_MESSENGER_SAVE_SUCCESS:
			return {
				...state,
				product: Object.assign(state.product!, actions.product),
				saveLoading: false,
			};
		case Actions.WEB_MESSENGER_SAVE_FAILURE:
			return {
				...state,
				saveLoading: false,
			};

		// WEB_MESSENGER_KNOWLEDGE:

		case KnowledgeActions.WEB_MESSENGER_KNOWLEDGE_GET_ALL_REQUEST:
			return {
				...state,
				loading: true,
			};
		case KnowledgeActions.WEB_MESSENGER_KNOWLEDGE_GET_ALL_FAILURE:
			return {
				...state,
				loading: false,
			};
		case KnowledgeActions.WEB_MESSENGER_KNOWLEDGE_GET_ALL_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					knowledge: actions.knowledge,
				},
				loading: false,
			};

		case KnowledgeActions.WEB_MESSENGER_KNOWLEDGE_SAVE_ALL_REQUEST:
			return {
				...state,
				saveLoading: true,
			};

		case KnowledgeActions.WEB_MESSENGER_KNOWLEDGE_SAVE_ALL_FAILURE:
			return {
				...state,
				saveLoading: false,
			};

		case KnowledgeActions.WEB_MESSENGER_KNOWLEDGE_SAVE_ALL_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					knowledge: actions.knowledge,
				},
				saveLoading: false,
			};
		case KnowledgeActions.WEB_MESSENGER_KNOWLEDGE_SAVE_REQUEST:
			return {
				...state,
				saveLoading: true,
			};
		case KnowledgeActions.WEB_MESSENGER_KNOWLEDGE_SAVE_FAILURE:
			return {
				...state,
				saveLoading: false,
			};

		case KnowledgeActions.WEB_MESSENGER_KNOWLEDGE_EDIT_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					knowledge: state.product!.knowledge!.map((article) =>
						article.id !== actions.article!.id ? article : Object.assign(article, actions.article),
					),
				},
				saveLoading: false,
			};

		case KnowledgeActions.WEB_MESSENGER_KNOWLEDGE_SAVE_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					knowledge: [...state.product?.knowledge!, actions.article],
				},
				saveLoading: false,
			};
		case KnowledgeActions.WEB_MESSENGER_KNOWLEDGE_DELETE_REQUEST:
			return {
				...state,
				loading: true,
			};

		case KnowledgeActions.WEB_MESSENGER_KNOWLEDGE_DELETE_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					knowledge: [...state.product!.knowledge!].filter((article) => article.id !== actions.id),
				},
				loading: false,
			};

		case KnowledgeActions.WEB_MESSENGER_KNOWLEDGE_DELETE_FAILURE:
			return {
				...state,
				loading: false,
			};

		// WEB_MESSENGER_FAQ:

		case FaqActions.WEB_MESSENGER_FAQ_GET_SUCCESS:
			return {
				...state,
				saveLoading: false,
			};

		case FaqActions.WEB_MESSENGER_FAQ_SAVE_REQUEST:
			return {
				...state,
				saveLoading: true,
			};
		case FaqActions.WEB_MESSENGER_FAQ_SAVE_FAILURE:
			return {
				...state,
				saveLoading: false,
			};

		case FaqActions.WEB_MESSENGER_FAQ_EDIT_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					faqs: state.product!.faqs!.map((faq) =>
						faq.id !== actions.faq!.id ? faq : Object.assign(faq, actions.faq),
					),
				},
				saveLoading: false,
			};
		case FaqActions.WEB_MESSENGER_FAQ_SAVE_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					faqs: [...state.product?.faqs!, actions.faq],
				},
				saveLoading: false,
			};

		case FaqActions.WEB_MESSENGER_FAQ_SAVE_ALL_REQUEST:
			return {
				...state,
				saveLoading: true,
			};

		case FaqActions.WEB_MESSENGER_FAQ_SAVE_ALL_FAILURE:
			return {
				...state,
				saveLoading: false,
			};

		case FaqActions.WEB_MESSENGER_FAQ_SAVE_ALL_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					faqs: actions.faqs,
				},
				saveLoading: false,
			};

		case FaqActions.WEB_MESSENGER_FAQ_GET_ALL_REQUEST:
			return {
				...state,
				loading: true,
			};

		case FaqActions.WEB_MESSENGER_FAQ_GET_ALL_FAILURE:
			return {
				...state,
				loading: false,
			};
		case FaqActions.WEB_MESSENGER_FAQ_GET_ALL_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					faqs: actions.faqs,
				},
				loading: false,
			};
		case FaqActions.WEB_MESSENGER_FAQ_DELETE_REQUEST:
			return {
				...state,
				loading: true,
			};

		case FaqActions.WEB_MESSENGER_FAQ_DELETE_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					faqs: [...state.product!.faqs!].filter((faq) => faq.id !== actions.id),
				},
				loading: false,
			};

		case FaqActions.WEB_MESSENGER_FAQ_DELETE_FAILURE:
			return {
				...state,
				loading: false,
			};

		// WEB_MESSENGER_ONBOARDING:

		case OnboardingActions.WEB_MESSENGER_ONBOARDING_GET_ALL_REQUEST:
			return {
				...state,
				loading: true,
			};

		case OnboardingActions.WEB_MESSENGER_ONBOARDING_GET_ALL_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					onboarding_pages: actions.onboarding_pages,
				},
				loading: false,
			};

		case OnboardingActions.WEB_MESSENGER_ONBOARDING_GET_ALL_FAILURE:
			return {
				...state,
				loading: false,
			};
		case OnboardingActions.WEB_MESSENGER_ONBOARDING_SAVE_REQUEST:
			return {
				...state,
				saveLoading: true,
			};

		case OnboardingActions.WEB_MESSENGER_ONBOARDING_EDIT_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					onboarding_pages: state.product!.onboarding_pages!.map((page) =>
						page.id !== actions.onboarding_page.id
							? page
							: Object.assign(page, actions.onboarding_page),
					),
				},
				saveLoading: false,
			};

		case OnboardingActions.WEB_MESSENGER_ONBOARDING_SAVE_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					onboarding_pages: [...state.product?.onboarding_pages!, actions.onboarding_page],
				},
				saveLoading: false,
			};

		case OnboardingActions.WEB_MESSENGER_ONBOARDING_SAVE_FAILURE:
			return {
				...state,
				saveLoading: false,
			};
		case OnboardingActions.WEB_MESSENGER_ONBOARDING_SAVE_ALL_REQUEST:
			return {
				...state,
				saveLoading: true,
			};

		case OnboardingActions.WEB_MESSENGER_ONBOARDING_SAVE_ALL_FAILURE:
			return {
				...state,
				saveLoading: false,
			};

		case OnboardingActions.WEB_MESSENGER_ONBOARDING_SAVE_ALL_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					onboarding_pages: actions.onboarding_pages,
				},
				saveLoading: false,
			};

		case OnboardingActions.WEB_MESSENGER_ONBOARDING_DELETE_REQUEST:
			return {
				...state,
				loading: true,
			};

		case OnboardingActions.WEB_MESSENGER_ONBOARDING_DELETE_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					onboarding_pages: [...state.product!.onboarding_pages!].filter(
						(page) => page.id !== actions.id,
					),
				},
				loading: false,
			};

		case OnboardingActions.WEB_MESSENGER_ONBOARDING_DELETE_FAILURE:
			return {
				...state,
				loading: false,
			};

		//WEB_MESSENGER_ACCESSIBILITY
		case AccessibilityActions.WEB_MESSENGER_ACCESSIBILITY_INFORMATION_GET_ALL_REQUEST:
			return {
				...state,
				loading: true,
			};
		case AccessibilityActions.WEB_MESSENGER_ACCESSIBILITY_INFORMATION_GET_ALL_FAILURE:
			return {
				...state,
				loading: false,
			};
		case AccessibilityActions.WEB_MESSENGER_ACCESSIBILITY_INFORMATION_GET_ALL_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					accessibility_information: actions.accessibility_information,
				},
				loading: false,
			};

		case AccessibilityActions.WEB_MESSENGER_ACCESSIBILITY_INFORMATION_SAVE_REQUEST:
			return {
				...state,
				saveLoading: true,
			};
		case AccessibilityActions.WEB_MESSENGER_ACCESSIBILITY_INFORMATION_SAVE_FAILURE:
			return {
				...state,
				saveLoading: false,
			};
		case AccessibilityActions.WEB_MESSENGER_ACCESSIBILITY_INFORMATION_SAVE_SUCCESS:
			return {
				...state,
				saveLoading: false,
			};

		case PrivacyPolicyActions.WEB_MESSENGER_PRIVACY_POLICY_GET_ALL_REQUEST:
			return {
				...state,
				loading: true,
			};
		case PrivacyPolicyActions.WEB_MESSENGER_PRIVACY_POLICY_GET_ALL_FAILURE:
			return {
				...state,
				loading: false,
			};
		case PrivacyPolicyActions.WEB_MESSENGER_PRIVACY_POLICY_GET_ALL_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					privacy_policy: actions.privacy_policy,
				},
				loading: false,
			};

		case PrivacyPolicyActions.WEB_MESSENGER_PRIVACY_POLICY_SAVE_REQUEST:
			return {
				...state,
				saveLoading: true,
			};
		case PrivacyPolicyActions.WEB_MESSENGER_PRIVACY_POLICY_SAVE_FAILURE:
			return {
				...state,
				saveLoading: false,
			};
		case PrivacyPolicyActions.WEB_MESSENGER_PRIVACY_POLICY_SAVE_SUCCESS:
			return {
				...state,
				saveLoading: false,
			};

		case TermsOfServiceActions.WEB_MESSENGER_TERMS_OF_SERVICE_GET_ALL_REQUEST:
			return {
				...state,
				loading: true,
			};
		case TermsOfServiceActions.WEB_MESSENGER_TERMS_OF_SERVICE_GET_ALL_FAILURE:
			return {
				...state,
				loading: false,
			};
		case TermsOfServiceActions.WEB_MESSENGER_TERMS_OF_SERVICE_GET_ALL_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					terms_of_service: actions.terms_of_service,
				},
				loading: false,
			};

		case TermsOfServiceActions.WEB_MESSENGER_TERMS_OF_SERVICE_SAVE_REQUEST:
			return {
				...state,
				saveLoading: true,
			};
		case TermsOfServiceActions.WEB_MESSENGER_TERMS_OF_SERVICE_SAVE_FAILURE:
			return {
				...state,
				saveLoading: false,
			};
		case TermsOfServiceActions.WEB_MESSENGER_TERMS_OF_SERVICE_SAVE_SUCCESS:
			return {
				...state,
				saveLoading: false,
			};

		default:
			return state;
	}
}
