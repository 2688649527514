import { combineReducers } from 'redux';
import globalNotifications, { GlobalNotificationBaseStateType } from './globalNotificationsReducer';

export const globalNotificationsReducer = combineReducers({
	globalNotifications,
});

export interface GlobalNotificationStateType {
	globalNotifications: GlobalNotificationBaseStateType;
}
