import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const SkipLink = styled.a`
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;

	&:focus {
		background: white;
		clip: auto;
		height: auto;
		width: auto;
		left: 10px;
		padding: 1rem;
		position: absolute;
		top: 0px;
		z-index: 10000;
	}
`;

interface Props {
	id: string;
	children?: string;
}

export const SkipNavA11y = ({ id, children = 'SKIP_TO_CONTENT' }: Props) => {
	const { t } = useTranslation();
	return <SkipLink href={`#${id}`}>{t(children)}</SkipLink>;
};
