export enum Actions {
	GROUPS_GET_REQUEST = 'GROUPS_GET_REQUEST',
	GROUPS_GET_SUCCESS = 'GROUPS_GET_SUCCESS',
	GROUPS_GET_FAILURE = 'GROUPS_GET_FAILURE',
	GROUPS_GET_ALL_REQUEST = 'GROUPS_GET_ALL_REQUEST',
	GROUPS_GET_ALL_SUCCESS = 'GROUPS_GET_ALL_SUCCESS',
	GROUPS_GET_ALL_FAILURE = 'GROUPS_GET_ALL_FAILURE',
	GROUPS_SAVE_REQUEST = 'GROUPS_SAVE_REQUEST',
	GROUPS_SAVE_SUCCESS = 'GROUPS_SAVE_SUCCESS',
	GROUPS_SAVE_FAILURE = 'GROUPS_SAVE_FAILURE',
}

export enum KnowledgeActions {
	GROUPS_KNOWLEDGE_GET_SUCCESS = 'GROUPS_KNOWLEDGE_GET_SUCCESS',
	GROUPS_KNOWLEDGE_GET_ALL_REQUEST = 'GROUPS_KNOWLEDGE_GET_ALL_REQUEST',
	GROUPS_KNOWLEDGE_GET_ALL_SUCCESS = 'GROUPS_KNOWLEDGE_GET_ALL_SUCCESS',
	GROUPS_KNOWLEDGE_GET_ALL_FAILURE = 'GROUPS_KNOWLEDGE_GET_ALL_FAILURE',
	GROUPS_KNOWLEDGE_SAVE_REQUEST = 'GROUPS_KNOWLEDGE_SAVE_REQUEST',
	GROUPS_KNOWLEDGE_EDIT_SUCCESS = 'GROUPS_KNOWLEDGE_EDIT_SUCCESS',
	GROUPS_KNOWLEDGE_SAVE_SUCCESS = 'GROUPS_KNOWLEDGE_SAVE_SUCCESS',
	GROUPS_KNOWLEDGE_SAVE_FAILURE = 'GROUPS_KNOWLEDGE_SAVE_FAILURE',
	GROUPS_KNOWLEDGE_SAVE_ALL_REQUEST = 'GROUPS_KNOWLEDGE_SAVE_ALL_REQUEST',
	GROUPS_KNOWLEDGE_SAVE_ALL_FAILURE = 'GROUPS_KNOWLEDGE_SAVE_ALL_FAILURE',
	GROUPS_KNOWLEDGE_SAVE_ALL_SUCCESS = 'GROUPS_KNOWLEDGE_SAVE_ALL_SUCCESS',
	GROUPS_KNOWLEDGE_DELETE_REQUEST = 'GROUPS_KNOWLEDGE_DELETE_REQUEST',
	GROUPS_KNOWLEDGE_DELETE_FAILURE = 'GROUPS_KNOWLEDGE_DELETE_FAILURE',
	GROUPS_KNOWLEDGE_DELETE_SUCCESS = 'GROUPS_KNOWLEDGE_DELETE_SUCCESS',
}

export enum FaqActions {
	GROUPS_FAQ_GET_ALL_REQUEST = 'GROUPS_FAQ_GET_ALL_REQUEST',
	GROUPS_FAQ_GET_ALL_SUCCESS = 'GROUPS_FAQ_GET_ALL_SUCCESS',
	GROUPS_FAQ_GET_ALL_FAILURE = 'GROUPS_FAQ_GET_ALL_FAILURE',
	GROUPS_FAQ_GET_SUCCESS = 'GROUPS_FAQ_GET_SUCCESS',
	GROUPS_FAQ_SAVE_SUCCESS = 'GROUPS_FAQ_SAVE_SUCCESS',
	GROUPS_FAQ_EDIT_SUCCESS = 'GROUPS_FAQ_EDIT_SUCCESS',
	GROUPS_FAQ_SAVE_REQUEST = 'GROUPS_FAQ_SAVE_REQUEST',
	GROUPS_FAQ_SAVE_FAILURE = 'GROUPS_FAQ_SAVE_FAILURE',
	GROUPS_FAQ_SAVE_ALL_REQUEST = 'GROUPS_FAQ_SAVE_ALL_REQUEST',
	GROUPS_FAQ_SAVE_ALL_FAILURE = 'GROUPS_FAQ_SAVE_ALL_FAILURE',
	GROUPS_FAQ_SAVE_ALL_SUCCESS = 'GROUPS_FAQ_SAVE_ALL_SUCCESS',
	GROUPS_FAQ_DELETE_REQUEST = 'GROUPS_FAQ_DELETE_REQUEST',
	GROUPS_FAQ_DELETE_FAILURE = 'GROUPS_FAQ_DELETE_FAILURE',
	GROUPS_FAQ_DELETE_SUCCESS = 'GROUPS_FAQ_DELETE_SUCCESS',
}

export enum OnboardingActions {
	GROUPS_ONBOARDING_GET_ALL_REQUEST = 'GROUPS_ONBOARDING_GET_ALL_REQUEST',
	GROUPS_ONBOARDING_GET_ALL_SUCCESS = 'GROUPS_ONBOARDING_GET_ALL_SUCCESS',
	GROUPS_ONBOARDING_GET_ALL_FAILURE = 'GROUPS_ONBOARDING_GET_ALL_FAILURE',
	GROUPS_ONBOARDING_SAVE_REQUEST = 'GROUPS_ONBOARDING_SAVE_REQUEST',
	GROUPS_ONBOARDING_SAVE_SUCCESS = 'GROUPS_ONBOARDING_SAVE_SUCCESS',
	GROUPS_ONBOARDING_EDIT_SUCCESS = 'GROUPS_ONBOARDING_EDIT_SUCCESS',
	GROUPS_ONBOARDING_SAVE_FAILURE = 'GROUPS_ONBOARDING_SAVE_FAILURE',
	GROUPS_ONBOARDING_SAVE_ALL_REQUEST = 'GROUPS_ONBOARDING_SAVE_ALL_REQUEST',
	GROUPS_ONBOARDING_SAVE_ALL_FAILURE = 'GROUPS_ONBOARDING_SAVE_ALL_FAILURE',
	GROUPS_ONBOARDING_SAVE_ALL_SUCCESS = 'GROUPS_ONBOARDING_SAVE_ALL_SUCCESS',
	GROUPS_ONBOARDING_DELETE_REQUEST = 'GROUPS_ONBOARDING_DELETE_REQUEST',
	GROUPS_ONBOARDING_DELETE_SUCCESS = 'GROUPS_ONBOARDING_DELETE_SUCCESS',
	GROUPS_ONBOARDING_DELETE_FAILURE = 'GROUPS_ONBOARDING_DELETE_FAILURE',
}

export enum AccessibilityActions {
	GROUPS_ACCESSIBILITY_INFORMATION_GET_ALL_REQUEST = 'GROUPS_ACCESSIBILITY_INFORMATION_GET_ALL_REQUEST',
	GROUPS_ACCESSIBILITY_INFORMATION_GET_ALL_SUCCESS = 'GROUPS_ACCESSIBILITY_INFORMATION_GET_ALL_SUCCESS',
	GROUPS_ACCESSIBILITY_INFORMATION_GET_ALL_FAILURE = 'GROUPS_ACCESSIBILITY_INFORMATION_GET_ALL_FAILURE',
	GROUPS_ACCESSIBILITY_INFORMATION_SAVE_REQUEST = 'GROUPS_ACCESSIBILITY_INFORMATION_SAVE_REQUEST',
	GROUPS_ACCESSIBILITY_INFORMATION_SAVE_SUCCESS = 'GROUPS_ACCESSIBILITY_INFORMATION_SAVE_SUCCESS',
	GROUPS_ACCESSIBILITY_INFORMATION_SAVE_FAILURE = 'GROUPS_ACCESSIBILITY_INFORMATION_SAVE_FAILURE',
}
export enum PrivacyPolicyActions {
	GROUPS_PRIVACY_POLICY_GET_ALL_REQUEST = 'GROUPS_PRIVACY_POLICY_GET_ALL_REQUEST',
	GROUPS_PRIVACY_POLICY_GET_ALL_SUCCESS = 'GROUPS_PRIVACY_POLICY_GET_ALL_SUCCESS',
	GROUPS_PRIVACY_POLICY_GET_ALL_FAILURE = 'GROUPS_PRIVACY_POLICY_GET_ALL_FAILURE',
	GROUPS_PRIVACY_POLICY_SAVE_REQUEST = 'GROUPS_PRIVACY_POLICY_SAVE_REQUEST',
	GROUPS_PRIVACY_POLICY_SAVE_SUCCESS = 'GROUPS_PRIVACY_POLICY_SAVE_SUCCESS',
	GROUPS_PRIVACY_POLICY_SAVE_FAILURE = 'GROUPS_PRIVACY_POLICY_SAVE_FAILURE',
}
export enum TermsOfServiceActions {
	GROUPS_TERMS_OF_SERVICE_GET_ALL_REQUEST = 'GROUPS_TERMS_OF_SERVICE_GET_ALL_REQUEST',
	GROUPS_TERMS_OF_SERVICE_GET_ALL_SUCCESS = 'GROUPS_TERMS_OF_SERVICE_GET_ALL_SUCCESS',
	GROUPS_TERMS_OF_SERVICE_GET_ALL_FAILURE = 'GROUPS_TERMS_OF_SERVICE_GET_ALL_FAILURE',
	GROUPS_TERMS_OF_SERVICE_SAVE_REQUEST = 'GROUPS_TERMS_OF_SERVICE_SAVE_REQUEST',
	GROUPS_TERMS_OF_SERVICE_SAVE_SUCCESS = 'GROUPS_TERMS_OF_SERVICE_SAVE_SUCCESS',
	GROUPS_TERMS_OF_SERVICE_SAVE_FAILURE = 'GROUPS_TERMS_OF_SERVICE_SAVE_FAILURE',
}
