import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import DateFnsUtils from '@date-io/date-fns';

import { ThemeProvider } from 'styled-components/macro';
import { create } from 'jss';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
	StylesProvider,
	ThemeProvider as MuiThemeProvider,
	jssPreset,
} from '@material-ui/core/styles';

import createTheme from './theme';
import Routes from './routes/Routes';
import { AppStateType } from './redux/reducers';
import { ConnectedRouter } from 'connected-react-router';

import { history } from './redux/store';

import { SnackbarProvider } from 'notistack';
import Notifier from './components/Notifier';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const jss = create({
	...jssPreset(),
	insertionPoint: document.getElementById('jss-insertion-point')!,
});

function App() {
	const theme = useSelector((state: AppStateType) => state.theme);

	return (
		<React.Fragment>
			<Helmet titleTemplate="%s | assisto" defaultTitle="assisto backend" />
			<StylesProvider jss={jss}>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<MuiThemeProvider theme={createTheme(theme.currentTheme)}>
						<ThemeProvider theme={createTheme(theme.currentTheme)}>
							<ConnectedRouter history={history}>
								<SnackbarProvider maxSnack={3}>
									<Routes />
									<Notifier />
								</SnackbarProvider>
							</ConnectedRouter>
						</ThemeProvider>
					</MuiThemeProvider>
				</MuiPickersUtilsProvider>
			</StylesProvider>
		</React.Fragment>
	);
}

export default App;
