import { Actions } from '../../types/products/questoTypes';

import { ProductQuesto } from '@assisto/typescript-axios/lib';

export type QuestoStateType = {
	product: ExtendedProductQuesto | null;
	loadingGet: boolean;
	loadingGetAll: boolean;
	loadingSave: boolean;
	loadingDelete: boolean;
};
interface ExtendedProductQuesto extends ProductQuesto {
	logo?: string;
}
const initialState: QuestoStateType = {
	product: null,
	loadingGet: false,
	loadingGetAll: false,
	loadingSave: false,
	loadingDelete: false,
};

export default function reducer(
	state: QuestoStateType = initialState,
	actions: {
		product?: ExtendedProductQuesto | ProductQuesto;
		id?: number;
		type: string;
	},
): any {
	switch (actions.type) {
		case Actions.QUESTO_GET_REQUEST:
			return {
				...state,
				product: null,
				loadingGet: true,
			};
		case Actions.QUESTO_GET_SUCCESS:
			return {
				...state,
				product: actions.product,
				loadingGet: false,
			};
		case Actions.QUESTO_GET_FAILURE:
			return {
				...state,
				loading: false,
			};
		case Actions.QUESTO_GET_ALL_REQUEST:
			return {
				...state,
				product: null,
				loadingGetAll: true,
			};
		case Actions.QUESTO_GET_ALL_SUCCESS:
			return {
				...state,
				product: actions.product,
				loadingGetAll: false,
			};
		case Actions.QUESTO_GET_ALL_FAILURE:
			return {
				...state,
				loadingGetAll: false,
			};
		case Actions.QUESTO_SAVE_REQUEST:
			return {
				...state,
				loadingSave: true,
			};
		case Actions.QUESTO_SAVE_SUCCESS:
			return {
				...state,
				product: Object.assign(state.product!, actions.product),
				loadingSave: false,
			};
		case Actions.QUESTO_SAVE_FAILURE:
			return {
				...state,
				loadingSave: false,
			};

		default:
			return state;
	}
}
