export enum Actions {
	PRODUCT_GET_ALL_REQUEST = 'PRODUCT_GET_ALL_REQUEST',
	PRODUCT_GET_ALL_SUCCESS = 'PRODUCT_GET_ALL_SUCCESS',
	PRODUCT_GET_ALL_FAILURE = 'PRODUCT_GET_ALL_FAILURE',
	PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST',
	PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS',
	PRODUCT_CREATE_FAILURE = 'PRODUCT_CREATE_FAILURE',
	PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST',
	PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS',
	PRODUCT_DELETE_FAILURE = 'PRODUCT_DELETE_FAILURE',
	PRODUCT_GET_REQUEST = 'PRODUCT_GET_REQUEST',
	PRODUCT_GET_SUCCESS = 'PRODUCT_GET_SUCCESS',
	PRODUCT_GET_FAILURE = 'PRODUCT_GET_FAILURE',
	PRODUCT_SAVE_REQUEST = 'PRODUCT_SAVE_REQUEST',
	PRODUCT_SAVE_SUCCESS = 'PRODUCT_SAVE_SUCCESS',
	PRODUCT_SAVE_FAILURE = 'PRODUCT_SAVE_FAILURE',
}
