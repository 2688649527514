export enum Actions {
	COMPANY_GET_REQUEST = 'COMPANY_GET_REQUEST',
	COMPANY_GET_SUCCESS = 'COMPANY_GET_SUCCESS',
	COMPANY_GET_FAILURE = 'COMPANY_GET_FAILURE',
	COMPANY_GET_ALL_REQUEST = 'COMPANY_GET_ALL_REQUEST',
	COMPANY_GET_ALL_SUCCESS = 'COMPANY_GET_ALL_SUCCESS',
	COMPANY_GET_ALL_FAILURE = 'COMPANY_GET_ALL_FAILURE',
	COMPANY_SAVE_REQUEST = 'COMPANY_SAVE_REQUEST',
	COMPANY_SAVE_SUCCESS = 'COMPANY_SAVE_SUCCESS',
	COMPANY_SAVE_FAILURE = 'COMPANY_SAVE_FAILURE',
	COMPANY_UPGRADE_CONFIG_REQUEST = 'COMPANY_UPGRADE_CONFIG_REQUEST',
	COMPANY_UPGRADE_CONFIG_SUCCESS = 'COMPANY_UPGRADE_CONFIG_SUCCESS',
	COMPANY_UPGRADE_CONFIG_FAILURE = 'COMPANY_UPGRADE_CONFIG_FAILURE',
	COMPANY_UPGRADE_DATA_REQUEST = 'COMPANY_UPGRADE_DATA_REQUEST',
	COMPANY_UPGRADE_DATA_SUCCESS = 'COMPANY_UPGRADE_DATA_SUCCESS',
	COMPANY_UPGRADE_DATA_FAILURE = 'COMPANY_UPGRADE_DATA_FAILURE',
	COMPANY_GET_BERANET_CONFIG_REQUEST = 'COMPANY_GET_BERANET_CONFIG_REQUEST',
	COMPANY_GET_BERANET_CONFIG_SUCCESS = 'COMPANY_GET_BERANET_CONFIG_SUCCESS',
	COMPANY_GET_BERANET_CONFIG_FAILURE = 'COMPANY_GET_BERANET_CONFIG_FAILURE',
	COMPANY_SAVE_BERANET_CONFIG_REQUEST = 'COMPANY_SAVE_BERANET_CONFIG_REQUEST',
	COMPANY_SAVE_BERANET_CONFIG_SUCCESS = 'COMPANY_SAVE_BERANET_CONFIG_SUCCESS',
	COMPANY_SAVE_BERANET_CONFIG_FAILURE = 'COMPANY_SAVE_BERANET_CONFIG_FAILURE',
}
