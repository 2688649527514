import React from 'react';
import styled, { createGlobalStyle } from 'styled-components/macro';
import { GlobalStyleProps } from '../types/styles';
import { CssBaseline } from '@material-ui/core';

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

const Root = styled.div`
	max-width: 520px;
	margin: 0 auto;
	justify-content: center;
	align-items: center;
	display: flex;
	min-height: 100%;
`;

const General: React.FC = ({ children }) => {
	return (
		<Root>
			<CssBaseline />
			<GlobalStyle />
			{children}
		</Root>
	);
};

export default General;
