import { put, all, takeEvery } from 'redux-saga/effects';
import { NotificationsActions } from '../actions/notificationsActions';

const SUCCESS_NOTIFICATION_BLACKLIST = [
	'AUTH_GET_CONFIG_SUCCESS',
	'AUTH_SIGN_IN_SUCCESS',
	'COMPANY_PRODUCTS_GET_ALL_SUCCESS',
	'WEB_BASIC_GET_ALL_SUCCESS',
	'COMPANY_GET_ALL_SUCCESS',
	'COMPANY_GET_SUCCESS',
	'COMPANY_CONFIG_GET_SUCCESS',
	'AGREEMENT_GET_LATEST_SUCCESS',
	'WEB_BASIC_GET_SUCCESS',
	'FAQ_GET_ALL_SUCCESS',
	'WEB_BASIC_FAQ_GET_ALL_SUCCESS',
	'KNOWLEDGE_GET_ALL_SUCCESS',
	'TERMS_OF_SERVICE_GET_ALL_SUCCESS',
	'ACCESSIBILITY_INFORMATION_GET_ALL_SUCCESS',
	'PRIVACY_POLICY_GET_ALL_SUCCESS',
	'MESSENGER_APP_GET_SUCCESS',
	'MESSENGER_APP_GET_ALL_SUCCESS',
	'BERANET_USERS_GET_ALL_SUCCESS',
	'USERS_GET_ALL_SUCCESS',
	'USERS_GET_SUCCESS',
	'BERANET_USERS_GET_SUCCESS',
	'COMPANY_GET_BERANET_CONFIG_SUCCESS',
];

function* requestFailed(payload: { type: string; ex: { response: { data: string[] } } }) {
	if (payload.type && payload.type.endsWith('_FAILURE')) {
		if (!payload.ex.response) {
			yield put({
				type: NotificationsActions.ADD_NOTIFICATION,
				notification: {
					message: `${payload.type}_UNKNOWN_ERROR`,
					type: 'error',
				},
			});

			return;
		}

		for (const errMsg of payload.ex.response.data) {
			if (typeof errMsg === 'object') {
				yield put({
					type: NotificationsActions.ADD_NOTIFICATION,
					notification: {
						message: `${payload.type}_UNKNOWN_ERROR`,
						type: 'error',
					},
				});
				continue;
			}

			yield put({
				type: NotificationsActions.ADD_NOTIFICATION,
				notification: {
					message: `${payload.type}_${errMsg.replace(' ', '_').toUpperCase()}`,
					type: 'error',
				},
			});
		}
	}

	if (
		payload.type &&
		payload.type.endsWith('_SUCCESS') &&
		SUCCESS_NOTIFICATION_BLACKLIST.indexOf(payload.type) === -1
	) {
		yield put({
			type: NotificationsActions.ADD_NOTIFICATION,
			notification: {
				message: `${payload.type}`,
				type: 'success',
			},
		});
	}
	return;
}

function* requestFailedWatcher() {
	yield takeEvery('*', requestFailed);
}

export default function* notificationSaga() {
	yield all([requestFailedWatcher()]);
}
