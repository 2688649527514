import { AppDispatchType } from '../store';
import { Actions } from '../types/agreementTypes';
import { Agreement } from '../../utils/api';

export enum AgreementType {
	LICENSE_AGREEMENT = 1,
	DATA_PROCESSING = 2,
}

export function getLatest(agreementType: AgreementType) {
	return async (dispatch: AppDispatchType) => {
		dispatch({ type: Actions.AGREEMENT_GET_LATEST_REQUEST, agreementType });

		return Agreement.getLatestAgreement(agreementType)
			.then((resp) => {
				dispatch({
					type: Actions.AGREEMENT_GET_LATEST_SUCCESS,
					agreement: resp.data,
					agreementType,
				});
			})
			.catch((ex) => {
				dispatch({ type: Actions.AGREEMENT_GET_LATEST_FAILURE, ex });
				throw ex;
			});
	};
}
