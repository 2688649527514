import * as React from 'react';
import styled, { withTheme } from 'styled-components/macro';

import {
	Grid,
	Hidden,
	AppBar as MuiAppBar,
	IconButton as MuiIconButton,
	Toolbar,
	Typography,
	Breadcrumbs as MuiBreadcrumbs,
} from '@material-ui/core';

import { Link as RouterLink, useLocation } from 'react-router-dom';
import Link, { LinkProps } from '@material-ui/core/Link';

import { Menu as MenuIcon } from '@material-ui/icons';

import { spacing } from '@material-ui/system';
import { FLAT_DASHBOARD_ROUTES } from '../routes';
import { useTranslation } from 'react-i18next';
import { matchPath } from 'react-router';
import UserDropdown from './UserDropdown';

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const AppBar = styled(MuiAppBar)`
	background: ${(props) => props.theme.header.background};
	color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
	svg {
		width: 22px;
		height: 22px;
	}
`;

type AppBarProps = {
	theme: {};
	onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;

interface LinkRouterProps extends LinkProps {
	to: string;
	replace?: boolean;
}

function RouterBreadcrumbs() {
	const location = useLocation();
	const pathNames = location.pathname.split('/').filter((x) => x);
	const { t } = useTranslation();
	return (
		<Breadcrumbs aria-label="breadcrumb">
			{/*<LinkRouter color="inherit" to="/">
        Home
      </LinkRouter>*/}
			{pathNames.map((value, index) => {
				const last = index === pathNames.length - 1;
				const to = `/${pathNames.slice(0, index + 1).join('/')}`;

				// skip /backend for now
				if (to === '/backend') {
					return null;
				}

				const label =
					FLAT_DASHBOARD_ROUTES.find((route) => !!matchPath(to, route.path)?.isExact)?.breadcrumb ??
					'';

				if (label === '') {
					return null;
				}

				return last ? (
					<Typography variant="h6" color="textPrimary" key={to}>
						{t(label)}
					</Typography>
				) : (
					<LinkRouter variant="h6" color="inherit" to={to} key={to}>
						{t(label)}
					</LinkRouter>
				);
			})}
		</Breadcrumbs>
	);
}

const AppBarComponent: React.FC<AppBarProps> = ({ onDrawerToggle }) => (
	<React.Fragment>
		<AppBar position="sticky" elevation={0}>
			<Toolbar>
				<Grid container alignItems="center">
					<Hidden mdUp>
						<Grid item>
							<IconButton color="inherit" aria-label="Open drawer" onClick={onDrawerToggle}>
								<MenuIcon />
							</IconButton>
						</Grid>
					</Hidden>
					<Grid item>
						<RouterBreadcrumbs />
					</Grid>
					<Grid item xs />
					<Grid item>
						{/*<MessagesDropdown />
            <NotificationsDropdown />
            <LanguagesDropdown />*/}
						<UserDropdown />
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	</React.Fragment>
);

export default withTheme(AppBarComponent);
