import Cookies from 'universal-cookie';

const TOKEN_KEY = 'token';
const JWT_KEY = 'jwt';
const THEME_KEY = 'theme';

export const cookies = new Cookies();

export function setToken(token: string): void {
	cookies.set(TOKEN_KEY, token, {
		path: '/',
		secure: process.env.NODE_ENV !== 'development',
		expires: new Date(2037, 11, 24, 23, 59, 59, 0),
	});
}

export function getToken(): string {
	return cookies.get(TOKEN_KEY);
}

export function deleteToken(): void {
	cookies.remove(TOKEN_KEY);
}

export function getJWT(): string | null {
	return localStorage.getItem(JWT_KEY);
}

export function setJWT(jwt: string): void {
	localStorage.setItem(JWT_KEY, jwt);
}

export function deleteJWT(): void {
	localStorage.removeItem(JWT_KEY);
}

export function getTheme(): string | null {
	return localStorage.getItem(JWT_KEY);
}

export function setTheme(theme: string): void {
	localStorage.setItem(THEME_KEY, theme);
}
