import { combineReducers } from 'redux';
import { History } from 'history';
import theme from './themeReducer';
import auth, { AuthStateType } from './authReducer';
import agreement, { AgreementStateType } from './agreementReducer';
import { CompanyStateType, companyReducer as company } from './company';
import { connectRouter, RouterState } from 'connected-react-router';
import { ProductsStateType, productsReducer as products } from './products';
import { UsersStateType, usersReducer as users } from './users';
import { NotificationsState } from '../types/notificationsTypes';
import { notificationsReducer as notifications } from './notificationsReducer';
import { CompositeStateType, compositeReducer as composite } from './composite';
import {
	GlobalNotificationStateType,
	globalNotificationsReducer as globalNotification,
} from './globalNotifications';

export const rootReducer = (history: History<unknown>) =>
	combineReducers({
		router: connectRouter(history),
		theme,
		auth,
		company,
		products,
		agreement,
		notifications,
		users,
		composite,
		globalNotification,
	});

export interface AppStateType {
	router?: RouterState<unknown>;
	theme: any;
	auth: AuthStateType;
	company: CompanyStateType;
	products: ProductsStateType;
	agreement: AgreementStateType;
	notifications: NotificationsState;
	users: UsersStateType;
	composite: CompositeStateType;
	globalNotification: GlobalNotificationStateType;
}

/*type RootReducerType = any & {
  theme: typeof theme;
  auth: typeof auth;
  company: typeof company;
};
export type AppStateType = ReturnType<RootReducerType>;*/
