import { Actions } from '../../types/users/beranetUsersTypes';
import { BeranetUser } from '@assisto/typescript-axios/lib';

export type BeranetUsersBaseStateType = {
	beranetUser: BeranetUser | null;
	beranetUsers: BeranetUser[] | null;
	loadingGet: boolean;
	loadingGetAll: boolean;
	loadingSave: boolean;
	page: number;
	limit: number;
};

const initialState: BeranetUsersBaseStateType = {
	beranetUser: null,
	beranetUsers: null,
	loadingGet: false,
	loadingGetAll: false,
	loadingSave: false,
	page: 1,
	limit: 0,
};

export default function reducer(
	state: BeranetUsersBaseStateType = initialState,
	actions: {
		beranetUsers?: BeranetUser[];
		page?: number;
		limit?: number;
		beranetUser?: BeranetUser;
		type: string;
	},
): any {
	switch (actions.type) {
		case Actions.BERANET_USERS_GET_ALL_REQUEST:
			return {
				...state,
				beranetUsers: null,
				loadingGetAll: true,
			};
		case Actions.BERANET_USERS_GET_ALL_SUCCESS:
			return {
				...state,
				beranetUsers: actions.beranetUsers,
				loadingGetAll: false,
				page: actions.page,
				limit: actions.limit,
			};

		case Actions.BERANET_USERS_GET_REQUEST:
			return {
				...state,
				beranetUser: null,
				loadingGet: true,
			};
		case Actions.BERANET_USERS_GET_SUCCESS:
			return {
				...state,
				beranetUser: actions.beranetUser,
				loadingGet: false,
			};

		case Actions.BERANET_USERS_SAVE_REQUEST:
			return {
				...state,
				loadingSave: true,
			};
		case Actions.BERANET_USERS_SAVE_SUCCESS:
			return {
				...state,
				loadingSave: false,
			};

		default:
			return state;
	}
}
