export enum Actions {
	USERS_GET_REQUEST = 'USERS_GET_REQUEST',
	USERS_GET_SUCCESS = 'USERS_GET_SUCCESS',
	USERS_GET_FAILURE = 'USERS_GET_FAILURE',
	USERS_GET_ALL_REQUEST = 'USERS_GET_ALL_REQUEST',
	USERS_GET_ALL_SUCCESS = 'USERS_GET_ALL_SUCCESS',
	USERS_GET_ALL_FAILURE = 'USERS_GET_ALL_FAILURE',
	USERS_SAVE_REQUEST = 'USERS_SAVE_REQUEST',
	USERS_SAVE_SUCCESS = 'USERS_SAVE_SUCCESS',
	USERS_SAVE_FAILURE = 'USERS_SAVE_FAILURE',
}
