import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { rootReducer } from '../reducers';
import rootSaga from '../sagas';

import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, createStore } from 'redux';

import { routerMiddleware } from 'connected-react-router';

export const history = createBrowserHistory();

export function configureStore() {
	const sagaMiddleware = createSagaMiddleware();
	const composeEnhancers = composeWithDevTools({});
	const store = createStore(
		rootReducer(history), // root reducer with router state
		{},
		composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware, thunk)),
	);
	sagaMiddleware.run(rootSaga);

	return store;
}

const store = configureStore();

export type AppDispatchType = typeof store.dispatch;

export default store;
