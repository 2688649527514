export enum Actions {
	COMPANY_CONFIG_GET_REQUEST = 'COMPANY_CONFIG_GET_REQUEST',
	COMPANY_CONFIG_GET_SUCCESS = 'COMPANY_CONFIG_GET_SUCCESS',
	COMPANY_CONFIG_GET_FAILURE = 'COMPANY_CONFIG_GET_FAILURE',
	COMPANY_ASSISTO_CONFIG_GET_REQUEST = 'COMPANY_ASSISTO_CONFIG_GET_REQUEST',
	COMPANY_ASSISTO_CONFIG_GET_SUCCESS = 'COMPANY_ASSISTO_CONFIG_GET_SUCCESS',
	COMPANY_ASSISTO_CONFIG_GET_FAILURE = 'COMPANY_ASSISTO_CONFIG_GET_FAILURE',
	COMPANY_CONFIG_SAVE_REQUEST = 'COMPANY_CONFIG_SAVE_REQUEST',
	COMPANY_CONFIG_SAVE_SUCCESS = 'COMPANY_CONFIG_SAVE_SUCCESS',
	COMPANY_CONFIG_SAVE_FAILURE = 'COMPANY_CONFIG_SAVE_FAILURE',
	COMPANY_GLOBAL_CONFIG_SAVE_REQUEST = 'COMPANY_GLOBAL_CONFIG_SAVE_REQUEST',
	COMPANY_GLOBAL_CONFIG_SAVE_SUCCESS = 'COMPANY_GLOBAL_CONFIG_SAVE_SUCCESS',
	COMPANY_GLOBAL_CONFIG_SAVE_FAILURE = 'COMPANY_GLOBAL_CONFIG_SAVE_FAILURE',
}
