import { Actions } from '../../types/company/companyTypes';
import {
	BeranetConfig,
	Company,
	CompanyProduct,
	CompanyWithProducts,
} from '@assisto/typescript-axios/lib';

export type CompanyBaseStateType = {
	company: CompanyWithProducts | null;
	companies: Company[] | null;
	companyProducts: CompanyProduct[] | null;
	beranetCompanyConfig: BeranetConfig | null;
	loadingGet: boolean;
	loadingGetAll: boolean;
	loadingSave: boolean;
	loadingUpgradeData: boolean;
	loadingUpgradeConfig: boolean;
	loadingGetBeranetConfig: boolean;
	loadingSaveBeranetConfig: boolean;
	page: number;
	limit: number;
};

const initialState: CompanyBaseStateType = {
	company: null,
	companies: null,
	companyProducts: null,
	beranetCompanyConfig: null,
	loadingGet: false,
	loadingGetAll: false,
	loadingSave: false,
	loadingUpgradeData: false,
	loadingUpgradeConfig: false,
	loadingGetBeranetConfig: false,
	loadingSaveBeranetConfig: false,
	page: 1,
	limit: 0,
};

export default function reducer(
	state: CompanyBaseStateType = initialState,
	actions: {
		companies?: Company[];
		page?: number;
		limit?: number;
		company?: CompanyWithProducts;
		beranetCompanyConfig?: BeranetConfig;
		type: string;
	},
): any {
	switch (actions.type) {
		case Actions.COMPANY_GET_ALL_REQUEST:
			return {
				...state,
				companies: null,
				loadingGetAll: true,
			};
		case Actions.COMPANY_GET_ALL_SUCCESS:
			return {
				...state,
				companies: actions.companies,
				loadingGetAll: false,
				page: actions.page,
				limit: actions.limit,
			};

		case Actions.COMPANY_GET_REQUEST:
			return {
				...state,
				company: null,
				loadingGet: true,
			};
		case Actions.COMPANY_GET_SUCCESS:
			return {
				...state,
				company: actions.company,
				loadingGet: false,
			};

		case Actions.COMPANY_SAVE_REQUEST:
			return {
				...state,
				loadingSave: true,
			};
		case Actions.COMPANY_SAVE_SUCCESS:
			return {
				...state,
				loadingSave: false,
			};

		case Actions.COMPANY_UPGRADE_DATA_REQUEST:
			return {
				...state,
				loadingUpgradeData: true,
			};
		case Actions.COMPANY_UPGRADE_DATA_SUCCESS:
			return {
				...state,
				loadingUpgradeData: false,
			};

		case Actions.COMPANY_UPGRADE_CONFIG_REQUEST:
			return {
				...state,
				loadingUpgradeConfig: true,
			};
		case Actions.COMPANY_UPGRADE_CONFIG_SUCCESS:
			return {
				...state,
				loadingUpgradeConfig: false,
			};
		case Actions.COMPANY_GET_BERANET_CONFIG_REQUEST:
			return {
				...state,
				loadingGetBeranetConfig: true,
			};
		case Actions.COMPANY_GET_BERANET_CONFIG_SUCCESS:
			return {
				...state,
				beranetCompanyConfig: actions.beranetCompanyConfig,
				loadingGetBeranetConfig: false,
			};
		case Actions.COMPANY_SAVE_BERANET_CONFIG_REQUEST:
			return {
				...state,
				loadingSaveBeranetConfig: true,
			};
		case Actions.COMPANY_SAVE_BERANET_CONFIG_SUCCESS:
			return {
				...state,
				beranetCompanyConfig: actions.beranetCompanyConfig,
				loadingSaveBeranetConfig: false,
			};

		default:
			return state;
	}
}
