import { combineReducers } from 'redux';
import beranetUsers, { BeranetUsersBaseStateType } from './beranetUsersReducer';
import users, { UserBaseStateType } from './usersReducer';

export const usersReducer = combineReducers({
	beranetUsers,
	users,
});

export interface UsersStateType {
	beranetUsers: BeranetUsersBaseStateType;
	users: UserBaseStateType;
}
