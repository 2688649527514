import { Actions } from '../../types/company/bankingTypes';
import { CompanyBanking } from '@assisto/typescript-axios/lib';

export type BankingStateType = {
	bankings: CompanyBanking[] | null;
	loadingGetAll: boolean;
	loadingSave: boolean;
};

const initialState: BankingStateType = {
	bankings: null,
	loadingGetAll: false,
	loadingSave: false,
};

export default function reducer(
	state: BankingStateType = initialState,
	actions: {
		bankings?: CompanyBanking[];
		type: string;
	},
): any {
	switch (actions.type) {
		case Actions.BANKING_GET_ALL_REQUEST:
			return {
				...state,
				bankings: null,
				loadingGetAll: true,
			};
		case Actions.BANKING_GET_ALL_SUCCESS:
			return {
				...state,
				bankings: actions.bankings,
				loadingGetAll: false,
			};

		case Actions.BANKING_SAVE_REQUEST:
			return {
				...state,
				loadingSave: true,
			};
		case Actions.BANKING_SAVE_SUCCESS:
			return {
				...state,
				loadingSave: false,
			};

		default:
			return state;
	}
}
