import { Actions } from '../../types/products/productTypes';
import { Product } from '@assisto/typescript-axios/lib';

export type ProductStateType = {
	product: Product | null;
	products: Product[] | null;
	loadingGet: boolean;
	loadingGetAll: boolean;
	loadingSave: boolean;
	page: number;
	limit: number;
};

const initialState: ProductStateType = {
	product: null,
	products: null,
	loadingGet: false,
	loadingGetAll: false,
	loadingSave: false,
	page: 1,
	limit: 0,
};

export default function reducer(
	state: ProductStateType = initialState,
	actions: {
		products?: Product[];
		page?: number;
		limit?: number;
		product?: Product;
		type: string;
	},
): any {
	switch (actions.type) {
		case Actions.PRODUCT_GET_ALL_REQUEST:
			return {
				...state,
				products: null,
				loadingGetAll: true,
			};
		case Actions.PRODUCT_GET_ALL_SUCCESS:
			return {
				...state,
				products: actions.products,
				loadingGetAll: false,
				page: actions.page,
				limit: actions.limit,
			};

		case Actions.PRODUCT_GET_REQUEST:
			return {
				...state,
				product: null,
				loadingGet: true,
			};
		case Actions.PRODUCT_GET_SUCCESS:
			return {
				...state,
				product: actions.product,
				loadingGet: false,
			};

		case Actions.PRODUCT_SAVE_REQUEST:
			return {
				...state,
				loadingSave: true,
			};
		case Actions.PRODUCT_SAVE_SUCCESS:
			return {
				...state,
				loadingSave: false,
			};

		default:
			return state;
	}
}
