export enum Actions {
	WEB_BASIC_GET_REQUEST = 'WEB_BASIC_GET_REQUEST',
	WEB_BASIC_GET_SUCCESS = 'WEB_BASIC_GET_SUCCESS',
	WEB_BASIC_GET_FAILURE = 'WEB_BASIC_GET_FAILURE',
	WEB_BASIC_GET_ALL_REQUEST = 'WEB_BASIC_GET_ALL_REQUEST',
	WEB_BASIC_GET_ALL_SUCCESS = 'WEB_BASIC_GET_ALL_SUCCESS',
	WEB_BASIC_GET_ALL_FAILURE = 'WEB_BASIC_GET_ALL_FAILURE',
	WEB_BASIC_SAVE_REQUEST = 'WEB_BASIC_SAVE_REQUEST',
	WEB_BASIC_SAVE_SUCCESS = 'WEB_BASIC_SAVE_SUCCESS',
	WEB_BASIC_SAVE_FAILURE = 'WEB_BASIC_SAVE_FAILURE',
}

export enum FaqActions {
	IMPORT_FAQS_REQUEST = 'IMPORT_FAQS_REQUEST ',
	IMPORT_FAQS_FAILURE = 'IMPORT_FAQS_FAILURE ',
	IMPORT_FAQS_SUCCESS = 'IMPORT_FAQS_SUCCESS',
	WEB_BASIC_FAQ_GET_ALL_REQUEST = 'WEB_BASIC_FAQ_GET_ALL_REQUEST',
	WEB_BASIC_FAQ_GET_ALL_SUCCESS = 'WEB_BASIC_FAQ_GET_ALL_SUCCESS',
	WEB_BASIC_FAQ_GET_ALL_FAILURE = 'WEB_BASIC_FAQ_GET_ALL_FAILURE',
	WEB_BASIC_FAQ_GET_SUCCESS = 'WEB_BASIC_FAQ_GET_SUCCESS',
	WEB_BASIC_FAQ_SAVE_SUCCESS = 'WEB_BASIC_FAQ_SAVE_SUCCESS',
	WEB_BASIC_FAQ_EDIT_SUCCESS = 'WEB_BASIC_FAQ_EDIT_SUCCESS',
	WEB_BASIC_FAQ_SAVE_REQUEST = 'WEB_BASIC_FAQ_SAVE_REQUEST',
	WEB_BASIC_FAQ_SAVE_FAILURE = 'WEB_BASIC_FAQ_SAVE_FAILURE',
	WEB_BASIC_FAQ_SAVE_ALL_REQUEST = 'WEB_BASIC_FAQ_SAVE_ALL_REQUEST',
	WEB_BASIC_FAQ_SAVE_ALL_FAILURE = 'WEB_BASIC_FAQ_SAVE_ALL_FAILURE',
	WEB_BASIC_FAQ_SAVE_ALL_SUCCESS = 'WEB_BASIC_FAQ_SAVE_ALL_SUCCESS',
	WEB_BASIC_FAQ_DELETE_REQUEST = 'WEB_BASIC_FAQ_DELETE_REQUEST',
	WEB_BASIC_FAQ_DELETE_FAILURE = 'WEB_BASIC_FAQ_DELETE_FAILURE',
	WEB_BASIC_FAQ_DELETE_SUCCESS = 'WEB_BASIC_FAQ_DELETE_SUCCESS',
}
