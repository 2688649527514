export enum Actions {
	COMPANY_PRODUCTS_GET_ALL_REQUEST = 'COMPANY_PRODUCTS_GET_ALL_REQUEST',
	COMPANY_PRODUCTS_GET_ALL_SUCCESS = 'COMPANY_PRODUCTS_GET_ALL_SUCCESS',
	COMPANY_PRODUCTS_GET_ALL_FAILURE = 'COMPANY_PRODUCTS_GET_ALL_FAILURE',
	COMPANY_PRODUCTS_CREATE_REQUEST = 'COMPANY_PRODUCTS_CREATE_REQUEST',
	COMPANY_PRODUCTS_CREATE_SUCCESS = 'COMPANY_PRODUCTS_CREATE_SUCCESS',
	COMPANY_PRODUCTS_CREATE_FAILURE = 'COMPANY_PRODUCTS_CREATE_FAILURE',
	COMPANY_PRODUCTS_DELETE_REQUEST = 'COMPANY_PRODUCTS_DELETE_REQUEST',
	COMPANY_PRODUCTS_DELETE_SUCCESS = 'COMPANY_PRODUCTS_DELETE_SUCCESS',
	COMPANY_PRODUCTS_DELETE_FAILURE = 'COMPANY_PRODUCTS_DELETE_FAILURE',
	COMPANY_PRODUCTS_GET_REQUEST = 'COMPANY_PRODUCTS_GET_REQUEST',
	COMPANY_PRODUCTS_GET_SUCCESS = 'COMPANY_PRODUCTS_GET_SUCCESS',
	COMPANY_PRODUCTS_GET_FAILURE = 'COMPANY_PRODUCTS_GET_FAILURE',
	COMPANY_PRODUCTS_GET_PRESET_REQUEST = 'COMPANY_PRODUCTS_GET_PRESET_REQUEST',
	COMPANY_PRODUCTS_GET_PRESET_SUCCESS = 'COMPANY_PRODUCTS_GET_PRESET_SUCCESS',
	COMPANY_PRODUCTS_GET_PRESET_FAILURE = 'COMPANY_PRODUCTS_GET_PRESET_FAILURE',
}
