import { Actions, FaqActions } from '../../types/products/webBasicTypes';
import { ProductWebBasic, ProductWebBasicFaq } from '@assisto/typescript-axios/lib';

interface ExtendedProductWebBasic extends ProductWebBasic {
	faqs?: any[];
}

export type WebBasicStateType = {
	product: ExtendedProductWebBasic | null;
	loading: boolean;
	saveLoading: boolean;
};

const initialState: WebBasicStateType = {
	product: null,
	loading: false,
	saveLoading: false,
};

export default function reducer(
	state: WebBasicStateType = initialState,
	actions: {
		product?: ExtendedProductWebBasic | ProductWebBasic;
		type: string;
		faqs?: ProductWebBasicFaq[];
		faq?: ProductWebBasicFaq;
		id?: number;
	},
): any {
	switch (actions.type) {
		case Actions.WEB_BASIC_GET_REQUEST:
			return {
				...state,
				product: null,
				loading: true,
			};
		case Actions.WEB_BASIC_GET_SUCCESS:
			return {
				...state,
				product: actions.product,
				loading: false,
			};
		case Actions.WEB_BASIC_GET_ALL_REQUEST:
			return {
				...state,
				product: null,
				loading: true,
			};
		case Actions.WEB_BASIC_GET_ALL_SUCCESS:
			return {
				...state,
				product: actions.product,
				loading: false,
			};
		case Actions.WEB_BASIC_SAVE_REQUEST:
			return {
				...state,
				saveLoading: true,
			};
		case Actions.WEB_BASIC_SAVE_SUCCESS:
			return {
				...state,
				saveLoading: false,
			};
		case Actions.WEB_BASIC_SAVE_FAILURE:
			return {
				...state,
				saveLoading: false,
			};

		// FAQ:

		case FaqActions.IMPORT_FAQS_REQUEST:
			return {
				...state,
				loading: true,
			};

		case FaqActions.IMPORT_FAQS_FAILURE:
			return {
				...state,
				loading: false,
			};
		case FaqActions.IMPORT_FAQS_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					faqs: actions.faqs,
				},
				loading: false,
			};

		case FaqActions.WEB_BASIC_FAQ_GET_ALL_REQUEST:
			return {
				...state,
				loading: true,
			};

		case FaqActions.WEB_BASIC_FAQ_GET_ALL_FAILURE:
			return {
				...state,
				loading: false,
			};
		case FaqActions.WEB_BASIC_FAQ_GET_ALL_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					faqs: actions.faqs,
				},
				loading: false,
			};
		case FaqActions.WEB_BASIC_FAQ_SAVE_ALL_REQUEST:
			return {
				...state,
				saveLoading: true,
			};

		case FaqActions.WEB_BASIC_FAQ_SAVE_ALL_FAILURE:
			return {
				...state,
				saveLoading: false,
			};

		case FaqActions.WEB_BASIC_FAQ_SAVE_ALL_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					faqs: actions.faqs,
				},
				saveLoading: false,
			};

		case FaqActions.WEB_BASIC_FAQ_SAVE_REQUEST:
			return {
				...state,
				saveLoading: true,
			};
		case FaqActions.WEB_BASIC_FAQ_SAVE_FAILURE:
			return {
				...state,
				saveLoading: false,
			};

		case FaqActions.WEB_BASIC_FAQ_SAVE_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					faqs: [...state.product?.faqs!, actions.faq],
				},
				saveLoading: false,
			};

		case FaqActions.WEB_BASIC_FAQ_EDIT_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					faqs: state.product!.faqs!.map((faq) =>
						faq.id !== actions.faq!.id ? faq : Object.assign(faq, actions.faq),
					),
				},
				saveLoading: false,
			};

		case FaqActions.WEB_BASIC_FAQ_DELETE_REQUEST:
			return {
				...state,
				loading: true,
			};

		case FaqActions.WEB_BASIC_FAQ_DELETE_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					faqs: [...state.product!.faqs!].map((faq) =>
						faq.id !== actions.id ? faq : { ...faq, deleted: +new Date() },
					),
				},
				loading: false,
			};

		case FaqActions.WEB_BASIC_FAQ_DELETE_FAILURE:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
}
