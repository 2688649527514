import { Notification } from '../types/notificationsTypes';

export enum NotificationsActions {
	ADD_NOTIFICATION = 'ADD_NOTIFICATION',
	REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION',
}

export const addNotification = (
	notification: Notification,
): { type: NotificationsActions; notification: Notification } => ({
	type: NotificationsActions.ADD_NOTIFICATION,
	notification,
});

export const removeNotification = (
	notification: Notification,
): { type: NotificationsActions; notification: Notification } => ({
	type: NotificationsActions.REMOVE_NOTIFICATION,
	notification,
});
