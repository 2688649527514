export enum Actions {
	BERANET_USERS_GET_REQUEST = 'BERANET_USERS_GET_REQUEST',
	BERANET_USERS_GET_SUCCESS = 'BERANET_USERS_GET_SUCCESS',
	BERANET_USERS_GET_FAILURE = 'BERANET_USERS_GET_FAILURE',
	BERANET_USERS_GET_ALL_REQUEST = 'BERANET_USERS_GET_ALL_REQUEST',
	BERANET_USERS_GET_ALL_SUCCESS = 'BERANET_USERS_GET_ALL_SUCCESS',
	BERANET_USERS_GET_ALL_FAILURE = 'BERANET_USERS_GET_ALL_FAILURE',
	BERANET_USERS_SAVE_REQUEST = 'BERANET_USERS_SAVE_REQUEST',
	BERANET_USERS_SAVE_SUCCESS = 'BERANET_USERS_SAVE_SUCCESS',
	BERANET_USERS_SAVE_FAILURE = 'BERANET_USERS_SAVE_FAILURE',
}
