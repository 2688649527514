import { Box, CircularProgress, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		minHeight: '100%',
		position: 'absolute',
		left: '50%',
		top: '50%',
		transform: 'translate(-50%, -50%)',
	},
}));

function Loader() {
	const { t } = useTranslation();
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			<CircularProgress color="primary" role="progressbar" aria-label={t('LOADING')} />
		</Box>
	);
}

export default Loader;
