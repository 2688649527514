import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationDE from './locales/de/translation.json';

const resources = {
	de: { translation: translationDE },
};

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: 'de',

		keySeparator: '.', // we do not use keys in form messages.welcome

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

i18n.on('languageChanged', (lng) => {
	document.documentElement.setAttribute('lang', lng);
});

export default i18n;
