export enum Actions {
	WEB_MESSENGER_GET_REQUEST = 'WEB_MESSENGER_GET_REQUEST',
	WEB_MESSENGER_GET_SUCCESS = 'WEB_MESSENGER_GET_SUCCESS',
	WEB_MESSENGER_GET_FAILURE = 'WEB_MESSENGER_GET_FAILURE',
	WEB_MESSENGER_GET_ALL_REQUEST = 'WEB_MESSENGER_GET_ALL_REQUEST',
	WEB_MESSENGER_GET_ALL_SUCCESS = 'WEB_MESSENGER_GET_ALL_SUCCESS',
	WEB_MESSENGER_GET_ALL_FAILURE = 'WEB_MESSENGER_GET_ALL_FAILURE',
	WEB_MESSENGER_SAVE_REQUEST = 'WEB_MESSENGER_SAVE_REQUEST',
	WEB_MESSENGER_SAVE_SUCCESS = 'WEB_MESSENGER_SAVE_SUCCESS',
	WEB_MESSENGER_SAVE_FAILURE = 'WEB_MESSENGER_SAVE_FAILURE',
}

export enum KnowledgeActions {
	WEB_MESSENGER_KNOWLEDGE_GET_SUCCESS = 'WEB_MESSENGER_KNOWLEDGE_GET_SUCCESS',
	WEB_MESSENGER_KNOWLEDGE_GET_ALL_REQUEST = 'WEB_MESSENGER_KNOWLEDGE_GET_ALL_REQUEST',
	WEB_MESSENGER_KNOWLEDGE_GET_ALL_SUCCESS = 'WEB_MESSENGER_KNOWLEDGE_GET_ALL_SUCCESS',
	WEB_MESSENGER_KNOWLEDGE_GET_ALL_FAILURE = 'WEB_MESSENGER_KNOWLEDGE_GET_ALL_FAILURE',
	WEB_MESSENGER_KNOWLEDGE_SAVE_REQUEST = 'WEB_MESSENGER_KNOWLEDGE_SAVE_REQUEST',
	WEB_MESSENGER_KNOWLEDGE_EDIT_SUCCESS = 'WEB_MESSENGER_KNOWLEDGE_EDIT_SUCCESS',
	WEB_MESSENGER_KNOWLEDGE_SAVE_SUCCESS = 'WEB_MESSENGER_KNOWLEDGE_SAVE_SUCCESS',
	WEB_MESSENGER_KNOWLEDGE_SAVE_FAILURE = 'WEB_MESSENGER_KNOWLEDGE_SAVE_FAILURE',
	WEB_MESSENGER_KNOWLEDGE_SAVE_ALL_REQUEST = 'WEB_MESSENGER_KNOWLEDGE_SAVE_ALL_REQUEST',
	WEB_MESSENGER_KNOWLEDGE_SAVE_ALL_FAILURE = 'WEB_MESSENGER_KNOWLEDGE_SAVE_ALL_FAILURE',
	WEB_MESSENGER_KNOWLEDGE_SAVE_ALL_SUCCESS = 'WEB_MESSENGER_KNOWLEDGE_SAVE_ALL_SUCCESS',
	WEB_MESSENGER_KNOWLEDGE_DELETE_REQUEST = 'WEB_MESSENGER_KNOWLEDGE_DELETE_REQUEST',
	WEB_MESSENGER_KNOWLEDGE_DELETE_FAILURE = 'WEB_MESSENGER_KNOWLEDGE_DELETE_FAILURE',
	WEB_MESSENGER_KNOWLEDGE_DELETE_SUCCESS = 'WEB_MESSENGER_KNOWLEDGE_DELETE_SUCCESS',
}

export enum FaqActions {
	WEB_MESSENGER_FAQ_GET_ALL_REQUEST = 'WEB_MESSENGER_FAQ_GET_ALL_REQUEST',
	WEB_MESSENGER_FAQ_GET_ALL_SUCCESS = 'WEB_MESSENGER_FAQ_GET_ALL_SUCCESS',
	WEB_MESSENGER_FAQ_GET_ALL_FAILURE = 'WEB_MESSENGER_FAQ_GET_ALL_FAILURE',
	WEB_MESSENGER_FAQ_GET_SUCCESS = 'WEB_MESSENGER_FAQ_GET_SUCCESS',
	WEB_MESSENGER_FAQ_SAVE_SUCCESS = 'WEB_MESSENGER_FAQ_SAVE_SUCCESS',
	WEB_MESSENGER_FAQ_EDIT_SUCCESS = 'WEB_MESSENGER_FAQ_EDIT_SUCCESS',
	WEB_MESSENGER_FAQ_SAVE_REQUEST = 'WEB_MESSENGER_FAQ_SAVE_REQUEST',
	WEB_MESSENGER_FAQ_SAVE_FAILURE = 'WEB_MESSENGER_FAQ_SAVE_FAILURE',
	WEB_MESSENGER_FAQ_SAVE_ALL_REQUEST = 'WEB_MESSENGER_FAQ_SAVE_ALL_REQUEST',
	WEB_MESSENGER_FAQ_SAVE_ALL_FAILURE = 'WEB_MESSENGER_FAQ_SAVE_ALL_FAILURE',
	WEB_MESSENGER_FAQ_SAVE_ALL_SUCCESS = 'WEB_MESSENGER_FAQ_SAVE_ALL_SUCCESS',
	WEB_MESSENGER_FAQ_DELETE_REQUEST = 'WEB_MESSENGER_FAQ_DELETE_REQUEST',
	WEB_MESSENGER_FAQ_DELETE_FAILURE = 'WEB_MESSENGER_FAQ_DELETE_FAILURE',
	WEB_MESSENGER_FAQ_DELETE_SUCCESS = 'WEB_MESSENGER_FAQ_DELETE_SUCCESS',
}

export enum OnboardingActions {
	WEB_MESSENGER_ONBOARDING_GET_ALL_REQUEST = 'WEB_MESSENGER_ONBOARDING_GET_ALL_REQUEST',
	WEB_MESSENGER_ONBOARDING_GET_ALL_SUCCESS = 'WEB_MESSENGER_ONBOARDING_GET_ALL_SUCCESS',
	WEB_MESSENGER_ONBOARDING_GET_ALL_FAILURE = 'WEB_MESSENGER_ONBOARDING_GET_ALL_FAILURE',
	WEB_MESSENGER_ONBOARDING_SAVE_REQUEST = 'WEB_MESSENGER_ONBOARDING_SAVE_REQUEST',
	WEB_MESSENGER_ONBOARDING_SAVE_SUCCESS = 'WEB_MESSENGER_ONBOARDING_SAVE_SUCCESS',
	WEB_MESSENGER_ONBOARDING_EDIT_SUCCESS = 'WEB_MESSENGER_ONBOARDING_EDIT_SUCCESS',
	WEB_MESSENGER_ONBOARDING_SAVE_FAILURE = 'WEB_MESSENGER_ONBOARDING_SAVE_FAILURE',
	WEB_MESSENGER_ONBOARDING_SAVE_ALL_REQUEST = 'WEB_MESSENGER_ONBOARDING_SAVE_ALL_REQUEST',
	WEB_MESSENGER_ONBOARDING_SAVE_ALL_FAILURE = 'WEB_MESSENGER_ONBOARDING_SAVE_ALL_FAILURE',
	WEB_MESSENGER_ONBOARDING_SAVE_ALL_SUCCESS = 'WEB_MESSENGER_ONBOARDING_SAVE_ALL_SUCCESS',
	WEB_MESSENGER_ONBOARDING_DELETE_REQUEST = 'WEB_MESSENGER_ONBOARDING_DELETE_REQUEST',
	WEB_MESSENGER_ONBOARDING_DELETE_SUCCESS = 'WEB_MESSENGER_ONBOARDING_DELETE_SUCCESS',
	WEB_MESSENGER_ONBOARDING_DELETE_FAILURE = 'WEB_MESSENGER_ONBOARDING_DELETE_FAILURE',
}

export enum AccessibilityActions {
	WEB_MESSENGER_ACCESSIBILITY_INFORMATION_GET_ALL_REQUEST = 'WEB_MESSENGER_ACCESSIBILITY_INFORMATION_GET_ALL_REQUEST',
	WEB_MESSENGER_ACCESSIBILITY_INFORMATION_GET_ALL_SUCCESS = 'WEB_MESSENGER_ACCESSIBILITY_INFORMATION_GET_ALL_SUCCESS',
	WEB_MESSENGER_ACCESSIBILITY_INFORMATION_GET_ALL_FAILURE = 'WEB_MESSENGER_ACCESSIBILITY_INFORMATION_GET_ALL_FAILURE',
	WEB_MESSENGER_ACCESSIBILITY_INFORMATION_SAVE_REQUEST = 'WEB_MESSENGER_ACCESSIBILITY_INFORMATION_SAVE_REQUEST',
	WEB_MESSENGER_ACCESSIBILITY_INFORMATION_SAVE_SUCCESS = 'WEB_MESSENGER_ACCESSIBILITY_INFORMATION_SAVE_SUCCESS',
	WEB_MESSENGER_ACCESSIBILITY_INFORMATION_SAVE_FAILURE = 'WEB_MESSENGER_ACCESSIBILITY_INFORMATION_SAVE_FAILURE',
}
export enum PrivacyPolicyActions {
	WEB_MESSENGER_PRIVACY_POLICY_GET_ALL_REQUEST = 'WEB_MESSENGER_PRIVACY_POLICY_GET_ALL_REQUEST',
	WEB_MESSENGER_PRIVACY_POLICY_GET_ALL_SUCCESS = 'WEB_MESSENGER_PRIVACY_POLICY_GET_ALL_SUCCESS',
	WEB_MESSENGER_PRIVACY_POLICY_GET_ALL_FAILURE = 'WEB_MESSENGER_PRIVACY_POLICY_GET_ALL_FAILURE',
	WEB_MESSENGER_PRIVACY_POLICY_SAVE_REQUEST = 'WEB_MESSENGER_PRIVACY_POLICY_SAVE_REQUEST',
	WEB_MESSENGER_PRIVACY_POLICY_SAVE_SUCCESS = 'WEB_MESSENGER_PRIVACY_POLICY_SAVE_SUCCESS',
	WEB_MESSENGER_PRIVACY_POLICY_SAVE_FAILURE = 'WEB_MESSENGER_PRIVACY_POLICY_SAVE_FAILURE',
}
export enum TermsOfServiceActions {
	WEB_MESSENGER_TERMS_OF_SERVICE_GET_ALL_REQUEST = 'WEB_MESSENGER_TERMS_OF_SERVICE_GET_ALL_REQUEST',
	WEB_MESSENGER_TERMS_OF_SERVICE_GET_ALL_SUCCESS = 'WEB_MESSENGER_TERMS_OF_SERVICE_GET_ALL_SUCCESS',
	WEB_MESSENGER_TERMS_OF_SERVICE_GET_ALL_FAILURE = 'WEB_MESSENGER_TERMS_OF_SERVICE_GET_ALL_FAILURE',
	WEB_MESSENGER_TERMS_OF_SERVICE_SAVE_REQUEST = 'WEB_MESSENGER_TERMS_OF_SERVICE_SAVE_REQUEST',
	WEB_MESSENGER_TERMS_OF_SERVICE_SAVE_SUCCESS = 'WEB_MESSENGER_TERMS_OF_SERVICE_SAVE_SUCCESS',
	WEB_MESSENGER_TERMS_OF_SERVICE_SAVE_FAILURE = 'WEB_MESSENGER_TERMS_OF_SERVICE_SAVE_FAILURE',
}
