import { RouteChildType } from '../types/routes';

export const flatten = (routes: Array<RouteChildType>) => {
	let flatRoutes: RouteChildType[] = [];
	routes.forEach((route) => {
		flatRoutes.push(route);

		if (route.children) {
			flatRoutes = [...flatRoutes, ...flatten(route.children)];
		}
	});

	return flatRoutes;
};
