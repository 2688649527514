import React from 'react';

import async from '../components/Async';

import { Sliders } from 'react-feather';
import SecurityIcon from '@material-ui/icons/Security';
import { DashboardRouteChildType, DashboardRouteType, RouteType } from '../types/routes';

import DashboardLayout from '../layouts/Dashboard';
import GeneralLayout from '../layouts/General';
import { flatten } from './util';

const Settings = async(() => import('../pages/settings/'));
const OrganizationConfiguration = async(
	() => import('../pages/settings/OrganizationConfiguration'),
);
const Products = async(() => import('../pages/settings/Products'));

const MessengerApp = async(() => import('../pages/settings/Products/MessengerApp'));
const WebBasic = async(() => import('../pages/settings/Products/WebBasic'));
const Questo = async(() => import('../pages/settings/Products/Questo'));
// Guards
const AuthGuard = async(() => import('../components/AuthGuard'));

const Page404 = async(() => import('../pages/general/Page404'));
const Page500 = async(() => import('../pages/general/Page500'));

const AutoLogin = async(() => import('../pages/auth/AutoLogin'));

const Imprint = async(() => import('../pages/general/imprint'));
const Privacy = async(() => import('../pages/general/privacy'));

const Admin = async(() => import('../pages/admin/'));
const Companies = async(() => import('../pages/admin/companies'));

const Company = async(() => import('../pages/admin/companies/company/index'));

const Defaults = async(() => import('../pages/admin/defaults'));
const Users = async(() => import('../pages/admin/users'));

const AssistoUsers = async(() => import('../pages/admin/users/assistoUsers'));
const AssistoUser = async(() => import('../pages/admin/users/assistoUsers/assistoUser'));
const BeranetUsers = async(() => import('../pages/admin/users/beranetUsers/'));
const BeranetUser = async(() => import('../pages/admin/users/beranetUsers/beranetUser'));
const Composites = async(() => import('../pages/admin/composites/index'));
const Composite = async(() => import('../pages/admin/composites/composite'));
const GlobalNotifications = async(
	() => import('../pages/admin/global_notifications/global_notification/index'),
);
const GlobalNotification = async(
	() => import('../pages/admin/global_notifications/global_notification'),
);

export const dashboardRoutes: DashboardRouteType = {
	name: 'DASHBOARD',
	path: '/backend',
	guard: AuthGuard,
	layout: DashboardLayout,
	children: [
		/*{
      name: "HOME",
      path: "/backend/dashboard",
      icon: <HomeIcon />,
      guard: AuthGuard,
      component: Dashboard,
      breadcrumb: "MODULES.DASHBOARD.BREADCRUMB",
    },*/
		{
			name: 'SETTINGS',
			path: '/backend/settings',
			icon: <Sliders />,
			guard: AuthGuard,
			component: Settings,
			breadcrumb: 'MODULES.SETTINGS.BREADCRUMB',
			canRender: (auth) => auth.config?.user?.beranetRoles?.admin ?? false,
			children: [
				{
					name: 'ORGANIZATION_CONFIGURATION',
					path: '/backend/settings/organization-configuration',
					component: OrganizationConfiguration,
					breadcrumb: 'MODULES.SETTINGS.PAGES.ORGANIZATION_CONFIGURATION.BREADCRUMB',
				},
				{
					name: 'PRODUCTS',
					path: '/backend/settings/products',
					component: Products,
					breadcrumb: 'MODULES.SETTINGS.PAGES.PRODUCTS.BREADCRUMB',
				},
				{
					name: 'MESSENGER_APP',
					path: '/backend/settings/products/messenger-app/:id',
					component: MessengerApp,
					breadcrumb: 'MODULES.SETTINGS.PAGES.PRODUCTS.PAGES.MESSENGER_APP.BREADCRUMB',
				},
				{
					name: 'WEB_BASIC',
					path: '/backend/settings/products/web-basic/:id',
					component: WebBasic,
					breadcrumb: 'MODULES.SETTINGS.PAGES.PRODUCTS.PAGES.WEB_BASIC.BREADCRUMB',
				},
				{
					name: 'QUESTO',
					path: '/backend/settings/products/questo/:id',
					component: Questo,
					breadcrumb: 'MODULES.SETTINGS.PAGES.PRODUCTS.PAGES.QUESTO.BREADCRUMB',
				},
			],
		},
		{
			name: 'ADMIN',
			path: '/backend/admin',
			icon: <SecurityIcon />,
			guard: AuthGuard,
			component: Admin,
			breadcrumb: 'MODULES.ADMIN.BREADCRUMB',
			canRender: (auth) => false,
			children: [
				{
					name: 'COMPANIES',
					path: '/backend/admin/companies',
					component: Companies,
					breadcrumb: 'MODULES.ADMIN.PAGES.COMPANIES.BREADCRUMB',
				},
				{
					name: 'COMPANIES',
					path: '/backend/admin/companies/company/:companyId',
					component: Company,
					breadcrumb: 'MODULES.ADMIN.PAGES.COMPANIES.PAGES.COMPANY.BREADCRUMB',
				},
				{
					name: 'MESSENGER_APP',
					path: '/backend/admin/companies/company/:companyId/messenger-app/:id',
					component: MessengerApp,
					breadcrumb: 'MODULES.SETTINGS.PAGES.PRODUCTS.PAGES.MESSENGER_APP.BREADCRUMB',
				},
				{
					name: 'WEB_BASIC',
					path: '/backend/admin/companies/company/:companyId/web-basic/:id',
					component: WebBasic,
					breadcrumb: 'MODULES.SETTINGS.PAGES.PRODUCTS.PAGES.WEB_BASIC.BREADCRUMB',
				},
				{
					name: 'QUESTO',
					path: '/backend/admin/companies/company/:companyId/questo/:id',
					component: Questo,
					breadcrumb: 'MODULES.SETTINGS.PAGES.PRODUCTS.PAGES.QUESTO.BREADCRUMB',
				},
				{
					name: 'USERS',
					path: '/backend/admin/users',
					component: Users,
					breadcrumb: 'MODULES.ADMIN.PAGES.USERS.BREADCRUMB',
				},
				{
					name: 'ASSISTO',
					path: '/backend/admin/users/assisto-users',
					component: AssistoUsers,
					breadcrumb: 'MODULES.ADMIN.PAGES.USERS.PAGES.ASSISTO.BREADCRUMB',
				},
				{
					name: 'ASSISTO_USER',
					path: '/backend/admin/users/assisto-users/assisto-user/:assistoUserId',
					component: AssistoUser,
					breadcrumb: 'MODULES.ADMIN.PAGES.USERS.PAGES.ASSISTO.PAGES.ASSISTO_USER.BREADCRUMB',
				},
				{
					name: 'BERANET',
					path: '/backend/admin/users/beranet-users',
					component: BeranetUsers,
					breadcrumb: 'MODULES.ADMIN.PAGES.USERS.PAGES.BERANET.BREADCRUMB',
				},
				{
					name: 'BERANET_USER',
					path: '/backend/admin/users/beranet-users/beranet-user/:beranetUserId',
					component: BeranetUser,
					breadcrumb: 'MODULES.ADMIN.PAGES.USERS.PAGES.BERANET.PAGES.BERANET_USER.BREADCRUMB',
				},
				{
					name: 'DEFAULTS',
					path: '/backend/admin/defaults',
					component: Defaults,
					breadcrumb: 'MODULES.ADMIN.PAGES.DEFAULTS.BREADCRUMB',
				},
				{
					name: 'COMPOSITES',
					path: '/backend/admin/composites',
					component: Composites,
					breadcrumb: 'MODULES.ADMIN.PAGES.COMPOSITES.BREADCRUMB',
				},
				{
					name: 'COMPOSITE_DATA',
					path: '/backend/admin/composites/composite/:compositeId?',
					component: Composite,
					breadcrumb: 'MODULES.ADMIN.PAGES.COMPOSITES.PAGES.COMPOSITE.BREADCRUMB',
				},
				{
					name: 'GLOBAL_NOTIFICATIONS',
					path: '/backend/admin/global_notifications',
					component: GlobalNotifications,
					breadcrumb: 'MODULES.ADMIN.PAGES.GLOBAL_NOTIFICATIONS.BREADCRUMB',
				},
				{
					name: 'GLOBAL_NOTIFICATION_DATA',
					path: '/backend/admin/global_notifications/global_notification/:globalNotificationId?',
					component: GlobalNotification,
					breadcrumb:
						'MODULES.ADMIN.PAGES.GLOBAL_NOTIFICATIONS.PAGES.GLOBAL_NOTIFICATION.BREADCRUMB',
				},
			],
		},
	],
};

const authRoutes: RouteType = {
	name: 'Auth',
	path: '/auth',
	layout: GeneralLayout,
	children: [
		{
			path: '/auth/autologin/:jwt',
			name: 'autologin',
			component: AutoLogin,
		},
	],
};

const generalRoutes: RouteType = {
	name: 'General',
	path: '/general',
	layout: GeneralLayout,
	children: [
		{
			path: '/general/imprint',
			name: 'IMPRINT',
			component: Imprint,
		},
		{
			path: '/general/privacy',
			name: 'PRIVACY',
			component: Privacy,
		},
		{
			path: '/general/404',
			name: '404 Page',
			component: Page404,
		},
		{
			path: '/general/500',
			name: '500 Page',
			component: Page500,
		},
	],
};

export const ROUTES: RouteType[] = [generalRoutes, dashboardRoutes, authRoutes];

export const FLAT_DASHBOARD_ROUTES: DashboardRouteChildType[] = flatten(dashboardRoutes.children);
