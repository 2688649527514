import { VariantType } from 'notistack';

export interface Notification {
	key: number;
	message: string;
	type: VariantType;
}

export interface NotificationsState {
	nextNotification: number;
	notifications: Notification[];
}

export const DEFAULT_NOTIFICATIONS_STATE: NotificationsState = {
	nextNotification: -1,
	notifications: [],
};
