import React from 'react';
import { ProviderContext, withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { removeNotification } from '../redux/actions/notificationsActions';
import { Notification } from '../redux/types/notificationsTypes';
import { withTranslation, WithTranslation } from 'react-i18next';
import { AppStateType } from '../redux/reducers';

class Notifier extends React.Component<
	{
		notifications: Notification[];
		removeNotification: typeof removeNotification;
	} & ProviderContext &
		WithTranslation,
	{ displayed: number[] }
> {
	constructor(
		props: {
			notifications: Notification[];
			removeNotification: typeof removeNotification;
		} & ProviderContext &
			WithTranslation,
	) {
		super(props);

		/**
		 * local state used to store the key of the displayed notifications
		 * to avoid displaying them multiple times
		 */
		this.state = {
			displayed: [],
		};
	}

	render = () => {
		const { notifications } = this.props;

		notifications.map((notification) => {
			return setTimeout(() => {
				// If notification already displayed, abort
				if (this.state.displayed.filter((key) => key === notification.key).length > 0) {
					return;
				}

				// Display notification using Snackbar
				this.props.enqueueSnackbar(this.props.t(notification.message), {
					variant: notification.type,
				});
				// Add notification's key to the local state
				this.setState({
					displayed: [...this.state.displayed, notification.key],
				});
				// Dispatch action to remove the notification from the redux store
				this.props.removeNotification(notification);
			}, 300);
		});

		return null;
	};
}

const mapStateToProps = (state: AppStateType) => {
	const { notifications } = state;
	return { notifications: notifications.notifications };
};

const mapDispatchToProps = {
	removeNotification: removeNotification,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTranslation()(withSnackbar(Notifier) as any));
